import React, { useState,useEffect } from 'react';
import styles from './mybooking.module.css';
import { Typography, Tabs, Tab, Box, Rating, Button } from '@mui/material';
import { Calendar, MapPin, ChevronDown } from 'react-feather';
import axios from 'axios';
import PropTypes from 'prop-types';
import moment from 'moment';
import {authData} from "../../../admin/components/getAuth";
import Loader from  '../../../admin/components/Loader';
import Modal from 'react-bootstrap/Modal';

function BookingBlock({item,changeStatus,upcoming,cancelled}){
  const [showMobileMenu, setShowMobileMenu] = React.useState(false);
  const [value, setValue] = React.useState(2);
  const [showRating, setShowRating] = React.useState(false);
  const [currencyRate, setConversationRate] = React.useState(1);

  useEffect(()=>{
    let conversationRateTemp = (item?.payable_amount/item?.total_amount);
    setConversationRate(conversationRateTemp);
  },[item]);

  return (<div className={`${styles.BookingCard}`}>
    <div className={`${styles.BookingCardUnder} ${showMobileMenu ? styles.Open : ''}`}>

      <div className={`${styles.BookingCardUnderTop}`}>
        <img src={item?.cars?.image_url} alt="" />
        <div className={`${styles.BookingCardUnderRow}`}>
          <div className={`${styles.BookingCarDeSec}`}>
            <p className={`${styles.BookingCarDeTitle}`}>{item?.cars?.title} {(item?.status === 'Pending') && <span className={`${styles.Pending}`}>Pending</span>}{(item?.status === 'Confirmed') && <span className={`${styles.Confirmed}`}>Confirmed</span>}{(item?.status === 'Completed') && <span className={`${styles.Completed}`}>Completed</span>}{(item?.status === 'Cancelled') && <span className={`${styles.Cancelled}`}>Cancelled</span>}</p>
            <p className={`${styles.BookingCarDeSTitle}`}>Reference Id: {item?.reference_id}</p>
            <div className={`${styles.BookingCarFeatured}`}>
              <span><img src="/images/gearshift.png" alt="" /> {item?.cars?.transmission}</span>
              <span><img src="/images/user.png" alt="" /> {item?.cars?.passengers} People</span>
              {(item?.cars?.air_conditioning === 1 || item?.cars?.air_conditioning=== "1") && <span><img src="/images/wind.png" alt="" /> A/C</span>}
              <span><img src="/images/brifcase.png" alt="" /> {item?.cars?.luggage}</span>
            </div>
          </div>
          <div className={`${styles.BookingCarDeSec2}`}>
            <p className={`${styles.BookingCarDeSTitle}`}><Calendar/> {moment(item?.start_date).format('ddd, MMM D, YYYY')} @ {moment(item?.start_date+' '+item?.start_time).format('hh:mm A')}</p>
            <p className={`${styles.BookingCarDeSTitle}`}><Calendar/> {moment(item?.end_date).format('ddd, MMM D, YYYY')} @ {moment(item?.end_date+' '+item?.end_time).format('hh:mm A')}</p>
            <p className={`${styles.BookingCarDeSTitle}`}><MapPin/> {item?.location}</p>
          </div>
          <div className={`${styles.ButtonArea}`}>
            <button onClick={() => setShowMobileMenu(v => !v, setShowRating(false))} className={`${styles.BookCarViewBU}`}><span>View Details <ChevronDown/></span></button>
            {(upcoming && !cancelled) && <button onClick={changeStatus.bind(this,item)} className={`${styles.BookCarCancelBU}`}>Cancel</button>}
          </div>
        </div>
      </div>

      <div className={`${styles.BookingCardUnderBottom}`}>
        <div className={`${styles.BookingCardUnderBottomRow}`}>
          <div className={`${styles.BookingDeConSec}`}>
            <div className={`${styles.CusDeArea}`}>
              <p className={`${styles.SmallSecSideTitle}`}>Customer Details</p>
              <div className={`${styles.SmallCardDESec}`}>
                <p className={`${styles.SmallCardDEList}`}><span>Name:</span>{item?.user_info?.first_name} {item?.user_info?.last_name}</p>
                <p className={`${styles.SmallCardDEList}`}><span>Address:</span>{item?.user_info?.address}, {item?.user_info?.city}, {item?.user_info?.state} {item?.user_info?.zip}</p>
                <p className={`${styles.SmallCardDEList}`}><span>Contact no.:</span>{item?.user_info?.phone}</p>
                <p className={`${styles.SmallCardDEList}`}><span>Email:</span>{item?.user_info?.email}</p>
                <p className={`${styles.SmallCardDEList}`}><span>No of Passenger(s):</span>{item?.user_info?.no_of_people} People</p>
                <p className={`${styles.SmallCardDEList}`}><span>Driver's Age:</span>{item?.user_info?.age} yrs</p>
              </div>
            </div>
            <p className={`${styles.SmallSecSideTitle}`}>Rental Details</p>
            <div className={`${styles.SmallCardDESec}`}>
              <div className={`${styles.SmallCardDESecHead}`}>
                <p className={`${styles.SmallCardDESecTitle}`}>Pick Up Details</p>
              </div>
              <p className={`${styles.SmallCardDEList}`}>{item?.location}</p>
              <p className={`${styles.SmallCardDEList}`}>{moment(item?.start_date).format('ddd, MMM D, YYYY')} @ {moment(item?.start_date+' '+item?.start_time).format('hh:mm A')}</p>
            </div>
            <div className={`${styles.SmallCardDESec}`}>
              <div className={`${styles.SmallCardDESecHead}`}>
                <p className={`${styles.SmallCardDESecTitle}`}>Drop Off Details</p>
              </div>
              <p className={`${styles.SmallCardDEList}`}>{item?.dropoff_location}</p>
              <p className={`${styles.SmallCardDEList}`}>{moment(item?.end_date).format('ddd, MMM D, YYYY')} @ {moment(item?.end_date+' '+item?.end_time).format('hh:mm A')}</p>
            </div>
          </div>
          <div className={`${styles.BookingDeConSec}`}>
            <p className={`${styles.SmallSecSideTitle}`}>Price Details</p>
            <div className={`${styles.SmallCardDESec2}`}>
              <div className={`${styles.SideCarDeBodyRow}`}>
                <p className={`${styles.SmallCardDEList}`}><b>{item?.cars?.title}</b> {item?.days} Day(s) {item?.currency+' '+((item?.cars?.price * currencyRate).toFixed(2))}/Day</p>
                <p className={`${styles.SmallCardDEList}`}>{item?.currency+' '+((item?.cars?.sub_amount * currencyRate).toFixed(2))}</p>
              </div>
            </div>
            {(item?.addons?.length > 0) && <div className={`${styles.SmallCardDESec2}`}>
              <div className={`${styles.SmallCardDESecHead}`}>
                <p className={`${styles.SmallCardDESecTitle}`}>Extras</p>
              </div>
              {item?.addons?.map((i,ind)=>{
                return (<div className={`${styles.SideCarDeBodyRow}`} key={ind}>
                <p className={`${styles.SmallCardDEList}`}><b>{i?.title}</b> {item?.days} Day(s) @ {item?.currency+' '+((i?.price * currencyRate).toFixed(2))}/Day</p>
                <p className={`${styles.SmallCardDEList}`}>{item?.currency+' '+((i?.sub_amount * currencyRate).toFixed(2))}</p>
              </div>);
              })}
            </div>}
            <div className={`${styles.SmallCardTaxSec}`}>
              <p className={`${styles.SmallCardTaxTitle}`}>Sub Total</p>
              <p className={`${styles.SmallCardTaxAmm}`}>{item?.currency+' '+((item?.amount * currencyRate).toFixed(2))}</p>
            </div>
            {(item?.discount_amount > 0) && <div className={`${styles.SmallCardTaxSec} ${styles.SmallCardTaxSecNew}`}>
              <p className={`${styles.SmallCardTaxTitle}`}>Discount</p>
              <p className={`${styles.SmallCardTaxAmm}`}>{item?.currency+' -'+((item?.discount_amount * currencyRate).toFixed(2))}</p>
            </div>}
            {(item?.dropoff_amount > 0) && <div className={`${styles.SmallCardTaxSec} ${styles.SmallCardTaxSecNew}`}>
              <p className={`${styles.SmallCardTaxTitle}`}>Delivery Fee</p>
              <p className={`${styles.SmallCardTaxAmm}`}>{item?.currency+' '+((item?.dropoff_amount * currencyRate).toFixed(2))}</p>
            </div>}
            <div className={`${styles.SmallCardTaxSec} ${styles.SmallCardTaxSecNew}`}>
              <p className={`${styles.SmallCardTaxTitle}`}>Security Amount</p>
              <p className={`${styles.SmallCardTaxAmm}`}>{item?.currency+' '+((item?.security_amount * currencyRate).toFixed(2))}</p>
            </div>
            <div className={`${styles.SmallCardTaxSec} ${styles.SmallCardTaxSecNew}`}>
              <p className={`${styles.SmallCardTaxTitle}`}>Payment Fee (3%)</p>
              <p className={`${styles.SmallCardTaxAmm}`}>{item?.currency+' '+((item?.payment_fee * currencyRate).toFixed(2))}</p>
            </div>
            <div className={`${styles.SmallCardTotal}`}>
              <p className={`${styles.SmallCardTaxTitle}`}>Total</p>
              <p className={`${styles.SmallCardTOtalAmm}`}>{item?.currency+' '+((item?.payable_amount).toFixed(2))}</p>
            </div>
          </div>
        </div>
        {(item?.status === 'Completed') && <div><button onClick={(event, newValue) => {
            setShowRating(true);
          }}  className={`${styles.BookCarViewBU}`}><span>Customer Ratings</span></button>
        
        
        {showRating && <Box
        sx={{
          '& > legend': { mt: 2 },
        }}
      >
        <Typography component="legend">Rating</Typography>
        <Rating
          name="simple-controlled"
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        />

      </Box>}
        </div>
        
        }
      </div>

    </div>
   
  </div>);
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Home() {
  const auth = authData();
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [bookingList, setBookingList] = useState({all:[],upcoming:[],previous:[],cancel:[],loading:false});
  const [modalObj, setModalObj] = React.useState({open:false,item:{}});

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  useEffect(()=>{
    function getBookings(){
      axios.get(process.env.REACT_APP_API_URL + 'v1/my-bookings', {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`
        }
      }).then(response => {
        let allBooking = response.data.data;
        bookingListSplit(allBooking);
      }).catch(error => {
        setBookingList({all:[],upcoming:[],previous:[],cancel:[],loading:true});
      });
    }
    if(!bookingList.loading){
      getBookings();
    }
  },[auth,bookingList]);

  const bookingListSplit = (allBooking)=>{
    let todayDate = moment();
    let upBooking = allBooking.filter(item=>{
      let bookDate = moment(item.end_date, 'YYYY-MM-DD');
      if (todayDate.isBefore(bookDate) && item.status !== 'Cancelled') {
        return true;
      }
      return false;
    }); 
      let prevBooking = allBooking.filter(item=>{
        let bookDate = moment(item.end_date, 'YYYY-MM-DD');
        if (!todayDate.isBefore(bookDate)) {
          return true;
        }
      return false;
    });
    let cancelBooking = allBooking.filter(item=>{
      if (item.status==='Cancelled') {
        return true;
      }
      return false;
    }); 

    setBookingList({all:allBooking,upcoming:upBooking,previous:prevBooking,cancel:cancelBooking,loading:true});
  }

  const changeStatus = (item)=>{
    setModalObj({open:true,item:item});
  }

  const modalCloseFunc = ()=>{
    setModalObj({open:false,item:{}});
  }

  const changeStatusConfirm = ()=>{
    let item = modalObj.item;
    let status = 'Cancelled';
    setModalObj({open:false,item:{}});
    setLoading(true);
    axios.put(process.env.REACT_APP_API_URL + 'v1/bookings/'+item.id,{status:status}, {
      headers: {
        Authorization: `Bearer ${auth?.api_token}`,
      }
    }).then(response => {
      setLoading(false);
      let bookingListTemp = bookingList.all;
      bookingListTemp = bookingListTemp.map(i=>{
        if(i === item){
          return {...i,status:'Cancelled'}
        }
        return i;
      });

      bookingListSplit(bookingListTemp);
    }).catch(error => {
      setLoading(false);
    });
  }

  return (
    <>
    {loading && <Loader />}
    <div className={`${styles.MainDivCustomer}`}>
      <img src="/images/backgroundGra.png" alt="" className={`${styles.BackGroundGra}`} />
      <div className='container'>
        <Box sx={{ width: '100%' }} className="CustomerDash">
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Upcoming Booking" {...a11yProps(0)} />
              <Tab label="Previous Booking" {...a11yProps(1)} />
              <Tab label="Cancel Booking" {...a11yProps(2)} />
              <Tab label="All" {...a11yProps(3)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0} className="CustomerDashDetails">
            {bookingList?.upcoming?.map((item,index)=>{
              return <BookingBlock key={index} item={item} upcoming={true} cancelled={false} changeStatus={changeStatus} />
            })}

            {(bookingList.upcoming.length === 0) && <div className={`${styles.NofileSec}`}>
              <div className={`${styles.NofileSecUnder}`}>
                <img src="/images/no-booking.png" alt="" />
                <p>No booking are available now</p>
              </div>
            </div>}

          </TabPanel>
          <TabPanel value={value} index={1} className="CustomerDashDetails">
            {bookingList?.previous?.map((item,index)=>{
              return <BookingBlock key={index} item={item} upcoming={false}  cancelled={false} changeStatus={changeStatus} />
            })}

            {(bookingList.previous.length === 0) && <div className={`${styles.NofileSec}`}>
              <div className={`${styles.NofileSecUnder}`}>
                <img src="/images/no-booking.png" alt="" />
                <p>No booking are available now</p>
              </div>
            </div>}
          </TabPanel>
          <TabPanel value={value} index={2} className="CustomerDashDetails">
            {bookingList.cancel.map((item,index)=>{
              return <BookingBlock key={index} item={item}  upcoming={false}  cancelled={true} changeStatus={changeStatus} />
            })}

            {(bookingList.cancel.length === 0) && <div className={`${styles.NofileSec}`}>
              <div className={`${styles.NofileSecUnder}`}>
                <img src="/images/no-booking.png" alt="" />
                <p>No booking are available now</p>
              </div>
            </div>}
          </TabPanel>
          <TabPanel value={value} index={3} className="CustomerDashDetails">
            {bookingList?.all?.map((item,index)=>{
              let upcoming = false;
              let todayDate = moment();
              let bookDate = moment(item.end_date, 'YYYY-MM-DD');
              if (todayDate.isBefore(bookDate)) {
                upcoming = true;
              }
              return <BookingBlock key={index} item={item} upcoming={upcoming} cancelled={item?.status === 'Cancelled'} changeStatus={changeStatus} />
            })}

            {(bookingList.all.length === 0) && <div className={`${styles.NofileSec}`}>
              <div className={`${styles.NofileSecUnder}`}>
                <img src="/images/no-booking.png" alt="" />
                <p>No booking are available now</p>
              </div>
            </div>}
          </TabPanel>
        </Box>
      </div>
    </div>

    <Modal show={modalObj?.open} onHide={modalCloseFunc.bind(this)} centered className='CusModal'>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Iteme</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='Message'>Are you sure you want to cancel this booking?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className='PopUpCanBU' onClick={modalCloseFunc.bind(this)}>No</Button>
          <Button className='PopUpSubmitBU' onClick={changeStatusConfirm.bind(this)}>Yes, Cancel</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}