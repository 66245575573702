import React, {useEffect, useState } from 'react';
import styles from './reservation.module.css';
import {  useNavigate,Link as RouterLink} from "react-router-dom";
import { Parallax } from 'react-parallax';
import {Box, Stepper, Step, StepLabel, Button, Typography, Link, Dialog, DialogContent, Checkbox,ButtonGroup } from '@mui/material';
import { AlertCircle, CheckCircle, Plus, X, Calendar, Clock, MapPin, Minus } from 'react-feather';
import { useForm,Controller } from "react-hook-form";
import moment from 'moment';
import axios from 'axios';
import { NumericFormat,PatternFormat  } from 'react-number-format';
import Loader from  '../../../admin/components/Loader';
import {authData} from "../../../admin/components/getAuth";
import Select from 'react-select';
import { PayPalButtons } from "@paypal/react-paypal-js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const steps = ['Reserve Car', 'Optional Add-Ons', 'Enter Information', 'Secure Payment'];

const addOnOptions = [
  { value: 'Beach Accessories', label: 'Beach Accessories' },
  { value: 'Tech Accessories', label: 'Tech Accessories' }
];

function LeftPanelBlock({handleSelect,bookingData,carList,addOnList,currency,currencyRate,currencyLoading,currentStep,updateReservationInfo}){
  const [selectedItem, setSelectedItem] = React.useState([]);
  const [selectedAddOns, setSelectedAddOns] = React.useState([]);
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [subTotalAmount, setSubTotalAmount] = React.useState(0);
  const [discountAmount, setDiscountAmount] = React.useState(0);
  const [discountAmount2, setDiscountAmount2] = React.useState(0);
  const [paymentFee, setPaymentFee] = React.useState(0);
  const dropOffAmount = 50;
  const [openReser, setOpenReser] = React.useState(false);
  const [reservationData, setReservationData] = useState({'location':{ value: 'Faleolo International Airport', label: 'Faleolo International Airportt' },dropOffLocation: { value: 'Faleolo International Airport', label: 'Faleolo International Airport' },startDate: moment().toDate(),startTime: moment().toDate(), endDate: moment().toDate(), endTime: moment().toDate(),isDiscount:0});

  useEffect(()=>{
    let carAmountTemp = 0;
    let totalAmountTemp = 0;
    let subTotalAmountTemp = 0;
    if(bookingData?.securityDeposit){
      totalAmountTemp += bookingData?.securityDeposit;
    }

    if(bookingData?.cars && bookingData?.cars?.length){
      let carListTemp = carList.map(item=>{
        let isCar = bookingData?.cars.find(i=> i.car_id === item.id);
        if(isCar){
          return item;
        }
        return null;
      });
      carListTemp = carListTemp.filter(el=> {
        return el != null;
      });
      setSelectedItem(carListTemp);

      subTotalAmountTemp += bookingData?.cars[0]?.sub_amount;
      carAmountTemp = bookingData?.cars[0]?.sub_amount;
    }
    if(bookingData?.addOns && bookingData?.addOns?.length){
      let addOnListTemp = addOnList.map(item=>{
        let isAddOn = bookingData?.addOns.find(i=> i.addon_id === item.id);
        if(isAddOn){
          subTotalAmountTemp += (bookingData?.days * item?.price * isAddOn?.quantity);
          item = {...item, quantity:isAddOn.quantity };
          return item;
        }
        return null;
      });
      addOnListTemp = addOnListTemp.filter(el=> {
        return el != null;
      });

      setSelectedAddOns(addOnListTemp);
    }else{
      setSelectedAddOns([]);
    }

    totalAmountTemp += subTotalAmountTemp;

    let discountAmountTemp =0;

    let discountAmountTemp2 =0;
    if(bookingData?.promotionalCode?.code){
      let disPercent = bookingData?.promotionalCode?.discount;
      disPercent = parseInt(disPercent);

      if(bookingData?.promotionalCode?.item_type === ''){
        discountAmountTemp2 = (subTotalAmountTemp * disPercent)/100;
      }

      if(bookingData?.promotionalCode?.item_type === 'car'){
        discountAmountTemp2 = (carAmountTemp * disPercent)/100;
      }

      if(bookingData?.promotionalCode?.item_type === 'addOn'){
        let addOnAmoutTemp = 0;
        
        addOnList.forEach(item=>{
          let isAddOn = bookingData?.addOns.find(i=> i.addon_id === item.id);
          if(isAddOn && item.id === bookingData?.promotionalCode?.item_id){
            addOnAmoutTemp += (bookingData?.days * item?.price * isAddOn?.quantity);
          }
        });
        discountAmountTemp2 = (addOnAmoutTemp * disPercent)/100;
      }

      setDiscountAmount2(discountAmountTemp2);
      setDiscountAmount(0);

      totalAmountTemp -= discountAmountTemp2;
    }else{
      if(bookingData?.isDiscount === 1){
        discountAmountTemp = (subTotalAmountTemp * 10)/100;
        setDiscountAmount(discountAmountTemp);
  
        totalAmountTemp -= discountAmountTemp;
      }
    }

    if(bookingData?.days < 7){
      totalAmountTemp += dropOffAmount;
    }

    if(currentStep === 4){
      let paymentFeeTemp = (((subTotalAmountTemp+dropOffAmount-discountAmountTemp)*3)/100);
      paymentFeeTemp = paymentFeeTemp.toFixed(2);
      paymentFeeTemp = parseFloat(paymentFeeTemp);
      setPaymentFee(paymentFeeTemp);

      totalAmountTemp += paymentFeeTemp;
    }
    
    setSubTotalAmount(subTotalAmountTemp);
    setTotalAmount(totalAmountTemp);

  },[bookingData,carList,addOnList,currentStep]);

  useEffect(()=>{
    if(bookingData){
      let pickUpTime = moment().format('YYYY-MM-DD')+' '+bookingData.pickUpTime;
      let dropOffTime = moment().format('YYYY-MM-DD')+' '+bookingData.dropOffTime;

      setReservationData({'location':{ value: 'Faleolo International Airport', label: 'Faleolo International Airport' },dropOffLocation: { value: 'Faleolo International Airport', label: 'Faleolo International Airport' },startDate: moment(bookingData.pickUpDate).toDate(), endDate: moment(bookingData.dropOffDate).toDate(), startTime: moment(pickUpTime).toDate(), endTime: moment(dropOffTime).toDate(),isDiscount:bookingData.isDiscount});
    }
  },[bookingData]);

  const handleClickOpenReser = () => {
    setOpenReser(true);
  };

  const handleCloseReser = (event, reason) => {
    if (reason && reason === "backdropClick") 
        return;
    setOpenReser(false);
  }

  const setStartDate = (event,date,type)=>{
    if(type === 'startDate'){
      let dayDiff2 = moment(date).diff(moment(),'days');
      if(dayDiff2 > 9){
        setReservationData({...reservationData, startDate: date, endDate: date,isDiscount:1});
      }else{
        setReservationData({...reservationData, startDate: date, endDate: date,isDiscount:0});
      }
    }
    if(type === 'endDate'){
      setReservationData({...reservationData, endDate: date});
    }
    if(type === 'startTime'){
      setReservationData({...reservationData, startTime: date});
    }
    if(type === 'endTime'){
      setReservationData({...reservationData, endTime: date});
    }
  }

  return (<><div className={`${styles.SmallSecSide}`}>
    <div className={`${styles.SmallSecSideCard}`}>
      <p className={`${styles.SmallSecSideTitle}`}>Rental Details</p>
      <div className={`${styles.SmallCardDESec}`}>
        <div className={`${styles.SmallCardDESecHead}`}>
          <p className={`${styles.SmallCardDESecTitle}`}>Dates & Times</p>
          <button className={`${styles.SideEditBU}`} onClick={handleClickOpenReser}>Edit</button>
        </div>
        <p className={`${styles.SmallCardDEList}`}>{moment(bookingData?.pickUpDate).format('ddd, MMM D, YYYY')} @ {moment(bookingData?.pickUpDate+' '+bookingData?.pickUpTime).format('hh:mm A')}</p>
        <p className={`${styles.SmallCardDEList}`}>{moment(bookingData?.dropOffDate).format('ddd, MMM D, YYYY')} @ {moment(bookingData?.dropOffDate+' '+bookingData?.dropOffTime).format('hh:mm A')}</p>
      </div>
      <div className={`${styles.SmallCardDESec}`}>
        <div className={`${styles.SmallCardDESecHead}`}>
          <p className={`${styles.SmallCardDESecTitle}`}>Pick-up Location</p>
          <button className={`${styles.SideEditBU}`} onClick={handleClickOpenReser}>Edit</button>
        </div>
        <p className={`${styles.SmallCardDEList}`}>{bookingData?.location}</p>
      </div>
      <div className={`${styles.SmallCardDESec}`}>
        <div className={`${styles.SmallCardDESecHead}`}>
          <p className={`${styles.SmallCardDESecTitle}`}>Drop-off Location</p>
          <button className={`${styles.SideEditBU}`} onClick={handleClickOpenReser}>Edit</button>
        </div>
        <p className={`${styles.SmallCardDEList}`}>{bookingData?.dropOffLocation}</p>
      </div>
    </div>  
    {(bookingData?.cars && bookingData?.cars?.length) && <div className={`${styles.SmallSecSideCard}`}>
      <div className={`${styles.SideCarDe}`}>
        <div className={`${styles.CarDetailsSec}`}>
          <p className={`${styles.CarDetailsSecTitle}`}>{selectedItem[0]?.title}</p>
          <p className={`${styles.CarDetailsSecSText}`}>{selectedItem[0]?.transmission}{(selectedItem[0]?.air_conditioning === 1 || selectedItem[0]?.air_conditioning === '1') && ', A/C'}</p>
          <p className={`${styles.CarDetailsSecSeat}`}><img src="/images/user.png" alt="" /> {selectedItem[0]?.passengers} People</p>
        </div>
        <div className={`${styles.SideCarIMG}`}>
          <img src={selectedItem[0]?.image_url} alt="" />
        </div>
      </div>
      <div className={`${styles.SmallCardDESec}`}>
        <div className={`${styles.SmallCardDESecHead}`}>
          <p className={`${styles.SmallCardDESecTitle}`}>Vehicle</p>
          <button className={`${styles.SideEditBU}`} onClick={handleSelect.bind(this,0)}>Edit</button>
        </div>
        <div className={`${styles.SideCarDeBodyRow}`}>
          <p className={`${styles.SmallCardDEList}`}>Time {bookingData?.days} Day(s) @ {!currencyLoading && currency+' '+((bookingData?.cars[0]?.price * currencyRate).toFixed(2))+'/Day'}</p>
          <p className={`${styles.SmallCardDEList}`}> {!currencyLoading && currency+' '+((bookingData?.cars[0]?.sub_amount * currencyRate).toFixed(2))}</p>
        </div>
        <div className={`${styles.SideCarDeBodyRow}`}>
          <p className={`${styles.SmallCardDEList}`}>Unlimited Mileage</p>
          <p className={`${styles.SmallCardDEList}`}>Included</p>
        </div>
      </div>
      {(selectedAddOns.length > 0) && <div className={`${styles.SmallCardDESec}`}>
        <div className={`${styles.SmallCardDESecHead}`}>
          <p className={`${styles.SmallCardDESecTitle}`}>Extras</p>
          <button className={`${styles.SideEditBU}`} onClick={handleSelect.bind(this,1)}>Edit</button>
        </div>
        {selectedAddOns?.map((item,index)=>{
          return (<div className={`${styles.SideCarDeBodyRow}`} key={index}>
            <p className={`${styles.SmallCardDEList} ${styles.ExtraLong}`}>{item?.quantity} X {item?.title} {bookingData?.days} Day(s) @ {!currencyLoading && currency+' '+((item?.price * currencyRate).toFixed(2))+'/Day'}</p>
            <p className={`${styles.SmallCardDEList} ${styles.ExtraAmmo}`}>{!currencyLoading && currency+' '+((bookingData?.days * item?.price * item?.quantity * currencyRate).toFixed(2))}</p>
          </div>)
        })}
      </div>}
      <div className={`${styles.SmallCardTaxSec} ${styles.SmallCardTaxSec2}`}>
        <p className={`${styles.SmallCardTaxTitle}`}>Sub Total</p>
        <p className={`${styles.SmallCardTaxAmm}`}> {!currencyLoading && currency+' '+((subTotalAmount * currencyRate).toFixed(2))}</p>
      </div>
      {(!bookingData?.promotionalCode?.code && bookingData?.isDiscount ===1) && <div className={`${styles.SmallCardTaxSec} ${styles.SmallCardTaxSec2}`}>
        <p className={`${styles.SmallCardTaxTitle}`}>Discount</p>
        <p className={`${styles.SmallCardTaxAmm}`}> {!currencyLoading && currency+' -'+((discountAmount * currencyRate).toFixed(2))}</p>
      </div>}
      {(bookingData?.promotionalCode?.code) && <div className={`${styles.SmallCardTaxSec} ${styles.SmallCardTaxSec2}`}>
        <p className={`${styles.SmallCardTaxTitle}`}>Promotional Code <br/><span style={{fontSize: '12px', fontWeight: 500}}>{bookingData?.promotionalCode?.code}</span></p>
        <p className={`${styles.SmallCardTaxAmm}`}> {!currencyLoading && currency+' -'+((discountAmount2 * currencyRate).toFixed(2))}</p>
      </div>}
      {(bookingData?.days < 7) && <div className={`${styles.SmallCardTaxSec} ${styles.SmallCardTaxSec2}`}>
        <p className={`${styles.SmallCardTaxTitle}`}>Delivery Fee</p>
        <p className={`${styles.SmallCardTaxAmm}`}> {!currencyLoading && currency+' '+((dropOffAmount * currencyRate).toFixed(2))}</p>
      </div>}
      <div className={(currentStep===4)?`${styles.SmallCardTaxSec} ${styles.SmallCardTaxSec2}`:`${styles.SmallCardTaxSec}`}>
        <p className={`${styles.SmallCardTaxTitle}`}>Security Amount</p>
        <p className={`${styles.SmallCardTaxAmm}`}> {!currencyLoading && currency+' '+((bookingData?.securityDeposit * currencyRate).toFixed(2))}</p>
      </div>
      {(currentStep===4) && <div className={`${styles.SmallCardTaxSec}`}>
        <p className={`${styles.SmallCardTaxTitle}`}>Payment Fee (3%)</p>
        <p className={`${styles.SmallCardTaxAmm}`}> {!currencyLoading && currency+' '+((paymentFee * currencyRate).toFixed(2))}</p>
      </div>}
      <div className={`${styles.SmallCardTotal}`}>
        <p className={`${styles.SmallCardTaxTitle}`}>Grand Total</p>
        <p className={`${styles.SmallCardTOtalAmm}`}>{!currencyLoading && currency+' '+((totalAmount * currencyRate).toFixed(2))}</p>
      </div>
    </div>}          
  </div>
  
  <Dialog
        maxWidth="md"
        open={openReser}
        onClose={handleCloseReser}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div className={`${styles.ImportantInfoSec}`}>
            <Button onClick={handleCloseReser} className={`${styles.InfoPopBU}`}><X/></Button>
            <div className={`${styles.ReserpopupSec}`}>
              <div className={`${styles.BannerSearchLocation}`}>
                <div className={`${styles.FixedFullSec}`}>
                  <label className={`${styles.BannerFormLabel} ${styles.LabelCus}`}>Pick Up Location</label>
                  <div className={`${styles.FormSec}`}>
                    <MapPin />
                    <Select className={`${styles.FormInput}`} placeholder={"Select Airport"} options={[ { value: 'Faleolo International Airport', label: 'Faleolo International Airport' }]} defaultValue={reservationData.location} />
                  </div>
                </div>
                <div className={`${styles.FixedFullSec}`}>
                  <label className={`${styles.BannerFormLabel} ${styles.LabelCus}`}>Drop Off Location</label>
                  <div className={`${styles.FormSec}`}>
                    <MapPin />
                    <Select className={`${styles.FormInput}`} placeholder={"Select Airport"} options={[ { value: 'Faleolo International Airport', label: 'Faleolo International Airport' }]} defaultValue={reservationData.dropOffLocation} />
                  </div>
                </div>
              </div>

              <div className={`${styles.BannerSearchDate}`}>
                <div className={`${styles.FixedFullSec}`}>
                  <label className={`${styles.BannerFormLabel}`}>Pick-Up Date</label>
                  <div className={`${styles.FormSec}`}>
                    <Calendar />
                    <DatePicker selected={reservationData.startDate} minDate={moment().toDate()} maxDate={moment().add(1,'y').toDate()} onChange={(date) => setStartDate(this,date,'startDate')} className={`${styles.FormInput}`} dateFormat="eee, d MMM y" />
                  </div>
                </div>
                <div className={`${styles.FixedFullSec}`}>
                  <label className={`${styles.BannerFormLabel}`}>Drop-Off Date</label>
                  <div className={`${styles.FormSec}`}>
                    <Calendar />
                    <DatePicker selected={reservationData.endDate} minDate={reservationData.startDate} maxDate={moment().add(1,'y').toDate()} onChange={(date) => setStartDate(this,date,'endDate')} className={`${styles.FormInput}`} dateFormat="eee, d MMM y" />
                  </div>
                </div>                        
              </div>

              <div className={`${styles.BannerSearchTime}`}>
                <div className={`${styles.FixedFullSec}`}>
                  <label className={`${styles.BannerFormLabel}`}>Pick-Up Time</label>
                  <div className={`${styles.FormSec}`}>
                    <Clock />
                    <DatePicker selected={reservationData.startTime} onChange={(date) => setStartDate(this,date,'startTime')} className={`${styles.FormInput}`} dateFormat="p" showTimeSelect timeFormat="p" timeIntervals={30} showTimeSelectOnly />
                  </div>
                </div>
                <div className={`${styles.FixedFullSec}`}>
                  <label className={`${styles.BannerFormLabel}`}>Drop-Off Time</label>
                  <div className={`${styles.FormSec}`}>
                    <Clock />
                    <DatePicker selected={reservationData.endTime} onChange={(date) => setStartDate(this,date,'endTime')} className={`${styles.FormInput}`} dateFormat="p" showTimeSelect timeFormat="p" timeIntervals={30} showTimeSelectOnly />
                  </div>
                </div>
              </div>

              <div className={`${styles.BannerSearchButton}`}>
                <button type='buttom' className={`${styles.SearchBU}`} onClick={(e)=>{setOpenReser(false); updateReservationInfo(reservationData);}}><span>Continue Reservation</span></button>
              </div>

            </div>
          </div>
        </DialogContent>
      </Dialog>
  </>)
}

function CarBlock({isSelected,item,selectCar,currency,currencyRate,currencyLoading,isDisabled}){
  const [open, setOpen] = React.useState(false);
  const [datePickerIsOpen, setDatePickerIsOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const openDatePicker = ()=>{
    setDatePickerIsOpen(datePickerIsOpen => !datePickerIsOpen);
  }

  return (<>
  <div className={`${styles.BigSideCarList}`}>
    <div className={(isSelected)?`${styles.BigSideCarListUnder}  ${styles.Selected}`:`${styles.BigSideCarListUnder}`}>
      <img src={item.image_url} alt={item.title} />
      <div className={`${styles.ReservCarDeSec}`}>
        <p className={`${styles.ReservCarDeTitle}`}>{item.title}</p>
        <p className={`${styles.ReservCarDeSTitle}`}>{item.type}</p>
        <div className={`${styles.CarFeatured}`}>
          <span><img src="/images/auto.png" alt="" /> {item.transmission}</span>
          <span><img src="/images/user.png" alt="" /> {item.passengers} People</span>
          {(item.air_conditioning === 1 || item.air_conditioning === '1') && <span><img src="/images/wind.png" alt="" /> A/C</span>}
          <span><img src="/images/brifcase.png" alt="" /> {item.luggage}</span>
        </div>
        <Link onClick={handleClickOpen} className={`${styles.CarInfoLink}`}><AlertCircle/> Important info</Link>
        <Link onClick={openDatePicker} className={`${styles.CarInfoLink} ${styles.CarInfoLinkNew}`}><Calendar/> Availability</Link>
        
        <div className="DatePickerNew">
          <DatePicker
            minDate={moment().toDate()}
            onClickOutside={openDatePicker}
            open={datePickerIsOpen}
            excludeDates={item.booking_dates.map(i=>{
              return moment(i).toDate()
            })}
          />
        </div>
        
      </div>
      <div className={`${styles.ReservCarPriceSec}`}>
        <p className={`${styles.ReservCarPrice}`}>{!currencyLoading && currency+' '+((item.price * currencyRate).toFixed(2))}</p>
        <p className={`${styles.ReservCarPriceTwo}`}>Per Day</p>
        {(isDisabled)?<button className={`${styles.HeaderBU} ${styles.HeaderBUDisabled}`} disabled={true}><span>Sold Out</span></button>:(isSelected ? <button className={`${styles.HeaderBUSE}`}><span>Selected</span></button>: <button className={`${styles.HeaderBU}`} onClick={selectCar.bind(this,item)}><span>Select</span></button>)}
      </div>
    </div>
  </div>
  
  <Dialog maxWidth="md" open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
    <DialogContent>
      <div className={`${styles.ImportantInfoSec}`}>
        <Button onClick={handleClose} className={`${styles.InfoPopBU}`}><X/></Button>
        <p className={`${styles.ImportantInfoTitle}`}>*Important information</p>
        {(item.drive_licence_req) && <div className={`${styles.ImpInfoRow}`}>
          <div className={`${styles.ImpInfoSmallSec}`}>
            <img src="/images/user-blue.png" alt="" />
            <p className={`${styles.ImpInfoTitle}`}>*Drive & license requirements</p>
          </div>
          <div className={`${styles.ImpInfoBigSec}`}>
            <div class={`${styles.ImpInfoBigSecText}`} dangerouslySetInnerHTML={{__html: item.drive_licence_req}}></div>
          </div>
        </div>}
        {(item.security_policy) && <div className={`${styles.ImpInfoRow}`}>
          <div className={`${styles.ImpInfoSmallSec}`}>
            <img src="/images/fuel-pump.png" alt="" />
            <p className={`${styles.ImpInfoTitle}`}>Security Policy</p>
          </div>
          <div className={`${styles.ImpInfoBigSec}`}>
            <div className={`${styles.ImpInfoBigSecText}`} dangerouslySetInnerHTML={{__html: item.security_policy}}></div>
          </div>
        </div>}
        {(item.insurance_coverage) && <div className={`${styles.ImpInfoRow}`}>
          <div className={`${styles.ImpInfoSmallSec}`}>
            <img src="/images/insurance.png" alt="" />
            <p className={`${styles.ImpInfoTitle}`}>Insurance coverage</p>
          </div>
          <div className={`${styles.ImpInfoBigSec}`}>
            <div className={`${styles.ImpInfoBigSecText}`} dangerouslySetInnerHTML={{__html: item.insurance_coverage}}></div>
          </div>
        </div>}
        {(item.fuel_policy) && <div className={`${styles.ImpInfoRow}`}>
          <div className={`${styles.ImpInfoSmallSec}`}>
            <img src="/images/fuel-pump.png" alt="" />
            <p className={`${styles.ImpInfoTitle}`}>Fuel Policy</p>
          </div>
          <div className={`${styles.ImpInfoBigSec}`}>
            <div className={`${styles.ImpInfoBigSecText}`} dangerouslySetInnerHTML={{__html: item.fuel_policy}}></div>
          </div>
        </div>}
        {(item.mileage) && <div className={`${styles.ImpInfoRow}`}>
          <div className={`${styles.ImpInfoSmallSec}`}>
            <img src="/images/speedometer.png" alt="" />
            <p className={`${styles.ImpInfoTitle}`}>Mileage</p>
          </div>
          <div className={`${styles.ImpInfoBigSec}`}>
            <div className={`${styles.ImpInfoBigSecText}`} dangerouslySetInnerHTML={{__html: item.mileage}}></div>
          </div>
        </div>}
        {(item.cleaning_policy) && <div className={`${styles.ImpInfoRow}`}>
          <div className={`${styles.ImpInfoSmallSec}`}>
            <img src="/images/clean.png" alt="" />
            <p className={`${styles.ImpInfoTitle}`}>Cleaning Policy</p>
          </div>
          <div className={`${styles.ImpInfoBigSec}`}>
            <div className={`${styles.ImpInfoBigSecText}`} dangerouslySetInnerHTML={{__html: item.cleaning_policy}}></div>
          </div>
        </div>}
      </div>
    </DialogContent>
  </Dialog>
</>)
}

function Step1({handleSelect,bookingData,carList,addOnList,selectCar,currency,currencyRate,currencyLoading,bookingCars,updateReservationInfo}){
  const [selectedItem, setSelectedItem] = React.useState([]);

  useEffect(()=>{
    if(bookingData?.cars && bookingData?.cars?.length){
      let carListTemp = carList.map(item=>{
        let isCar = bookingData?.cars.find(i=> i.car_id === item.id);
        if(isCar){
          return item;
        }
        return null;
      });
      carListTemp = carListTemp.filter(el=> {
        return el != null;
      });
      setSelectedItem(carListTemp);
    }
  },[bookingData,carList]);
  
  return (
    <div className={`${styles.SelectCarSec}`}>

      <LeftPanelBlock handleSelect={handleSelect} bookingData={bookingData} carList={carList} addOnList={addOnList} currency={currency} currencyRate={currencyRate} currencyLoading={currencyLoading} currentStep={1} updateReservationInfo={updateReservationInfo} />

      <div className={`${styles.ReservationBigSide}`}>
        <div className={`${styles.BigSideHead}`}>
          <div className={`${styles.BigHeadTextSec}`}>
            <p className={`${styles.BigHeadTitleText}`}>Choose a Vehicle</p>
            <p className={`${styles.BigHeadSmallText}`}>{carList?.length} Results</p>
          </div>
        </div>
        <div className={`${styles.ReservationBigListSec}`}>
          {carList?.map((item,index)=>{
            let isSelected = selectedItem.includes(item);
            return (<CarBlock key={index} item={item} isSelected={isSelected}  selectCar={selectCar} currency={currency} currencyRate={currencyRate} currencyLoading={currencyLoading} isDisabled={bookingCars.indexOf(item.id) > -1} />);
          })}
        </div>
      </div>

      

    </div>
  )
}

function Step2({handleSelect,bookingData,carList,addOnList,selectAddOn,updateAddOn,removeAddOn,currency,currencyRate,currencyLoading,bookingAddOns,updateReservationInfo}){
  const [tempAddOns, setTempAddOns] = React.useState([]);
  const [currentType, setCurrentType] = React.useState('Beach Accessories');

  useEffect(()=>{
    if(bookingData?.addOns && bookingData?.addOns?.length){
      let addOnListTemp = addOnList.map(item=>{
        let isAddOn = bookingData?.addOns.find(i=> i.addon_id === item.id);
        if(isAddOn){
          return {...item,selected:true, quantity: isAddOn.quantity};
        }
        return {...item,selected:false,quantity:0};
      });
      setTempAddOns(addOnListTemp);

    }else{
      let addOnListTemp = addOnList.map(item=>{
        return {...item,selected:false};
      });
      setTempAddOns(addOnListTemp);
    }
  },[bookingData,addOnList]);

  const typeChange = (selected)=>{
    setCurrentType(selected?.value);
  }

  return (
    <div className={`${styles.SelectCarSec}`}>

      <LeftPanelBlock handleSelect={handleSelect} bookingData={bookingData} carList={carList} addOnList={addOnList} currency={currency} currencyRate={currencyRate} currencyLoading={currencyLoading} currentStep={2} updateReservationInfo={updateReservationInfo} />

      <div className={`${styles.AddonsBigSide}`}>
        <h2 className={`${styles.AddonsBodyTitle}`}>Recommended Add-Ons</h2>
        <p className={`${styles.AddonsBodySTitle}`}>Included in the price</p>
        <ul className={`${styles.AddonsBodyList}`}>
          <li>Free cancellation up to 48 hours before pick up</li>
          <li>Unlimited mileage</li>
        </ul>

        

        <div>
          {tempAddOns?.map((item,index)=>{
            return (item.type === 'Primary Addons')?(<div className={`${styles.AddOnsList}`} key={index}>
            <div className={`${styles.AddOnsListBody}`}>
              <div className={`${styles.BodySec}`}>
                <img src={(item.image_url !== '')?item.image_url:'/images/noImage.jpg'} alt="" />
                <div className={`${styles.AddOnsListBodyText}`}>
                  <p className={`${styles.AccordingBodyTitle}`}>{item?.title} <span>{!currencyLoading && currency+' '+((item.price * currencyRate).toFixed(2))+'/Day'}</span></p>
                  <p className={`${styles.AccordingBodyText}`} dangerouslySetInnerHTML={{__html: item?.description}}></p>
                  {item?.selected && <ButtonGroup className={`${styles.ButtonGroupNew}`} size="small" aria-label="small outlined button group">
                      <Button className={`${styles.ButtonNew} ${styles.ButtonNew1}`} disabled={item?.quantity === 1} onClick={updateAddOn.bind(this,item,'sub')}><Minus/></Button>
                      <Button className={`${styles.ButtonNew} ${styles.ButtonNew2}`} disabled>{item?.quantity}</Button>
                      <Button className={`${styles.ButtonNew} ${styles.ButtonNew3}`} onClick={updateAddOn.bind(this,item,'add')}><Plus/></Button>
                    </ButtonGroup>}
                  <div className={`${styles.AccordingBUSec}`}>
                    {(item?.selected ? <><button className={`${styles.AddedBU}`}><CheckCircle/> Added</button><button className={`${styles.RemoveBU}`} onClick={removeAddOn.bind(this,item)}><X/> Remove</button></>:<button className={`${styles.AddBU}`} onClick={selectAddOn.bind(this,item)}><Plus/> Add</button>)}
                  </div>
                </div>
              </div>
            </div>
          </div>):null;
          })}
        </div>

        <div>
          {tempAddOns?.map((item,index)=>{
            return (item.type === 'Insurance Service')?(<div className={`${styles.AddOnsList}`} key={index}>
            <div className={`${styles.AddOnsListBody}`}>
              <div className={`${styles.BodySec}`}>
                <img src={(item.image_url !== '')?item.image_url:'/images/noImage.jpg'} alt="" />
                <div className={`${styles.AddOnsListBodyText}`}>
                  <p className={`${styles.AccordingBodyTitle}`}>{item?.title} <span>{!currencyLoading && currency+' '+((item.price * currencyRate).toFixed(2))+'/Day'}</span></p>
                  <p className={`${styles.AccordingBodyText}`} dangerouslySetInnerHTML={{__html: item?.description}}></p>
                  <div className={`${styles.AccordingBUSec}`}>
                    {item?.selected ? <><button className={`${styles.AddedBU}`}><CheckCircle/> Added</button><button className={`${styles.RemoveBU}`}  onClick={removeAddOn.bind(this,item)}><X/> Remove</button></>:<button className={`${styles.AddBU}`} onClick={selectAddOn.bind(this,item)}><Plus/> Add</button>}
                  </div>
                </div>
              </div>
            </div>
          </div>):null;
          })}
        </div>

        <div>
          <div className={`${styles.AddonDropdown}`}>
            <p className={`${styles.AddonDropdownTitle}`}>view more of our extras:</p>
            <Select className={`${styles.AddonDropdownFormInput}`} options={addOnOptions} defaultValue={{ value: 'Beach Accessories', label: 'Beach Accessories' }} onChange={typeChange} />
          </div>
          {tempAddOns?.map((item,index)=>{
            return (currentType === item.type)?(<div className={`${styles.AddOnsList}`} key={index}>
            <div className={`${styles.AddOnsListBody}`}>
              <div className={`${styles.BodySec}`}>
                <img src={(item.image_url !== '')?item.image_url:'/images/noImage.jpg'} alt="" />
                <div className={`${styles.AddOnsListBodyText}`}>
                  <p className={`${styles.AccordingBodyTitle}`}>{item?.title} <span>{!currencyLoading && currency+' '+((item.price * currencyRate).toFixed(2))+'/Day'}</span></p>
                  <p className={`${styles.AccordingBodyText}`} dangerouslySetInnerHTML={{__html: item?.description}}></p>
                  {item?.selected && <ButtonGroup className={`${styles.ButtonGroupNew}`} size="small" aria-label="small outlined button group">
                      <Button className={`${styles.ButtonNew} ${styles.ButtonNew1}`} disabled={item?.quantity === 1} onClick={updateAddOn.bind(this,item,'sub')}><Minus/></Button>
                      <Button className={`${styles.ButtonNew} ${styles.ButtonNew2}`} disabled>{item?.quantity}</Button>
                      <Button className={`${styles.ButtonNew} ${styles.ButtonNew3}`} onClick={updateAddOn.bind(this,item,'add')}><Plus/></Button>
                    </ButtonGroup>}
                  <div className={`${styles.AccordingBUSec}`}>
                  {(bookingAddOns.indexOf(item.id) > -1)?<button className={`${styles.AddBU} ${styles.AddBUDisabled}`} disabled={true}>Sold Out</button>:(item?.selected ? <><button className={`${styles.AddedBU}`}><CheckCircle/> Added</button><button className={`${styles.RemoveBU}`} onClick={removeAddOn.bind(this,item)}><X/> Remove</button></>:<button className={`${styles.AddBU}`} onClick={selectAddOn.bind(this,item)}><Plus/> Add</button>)}
                  </div>
                </div>
              </div>
            </div>
          </div>):null;
          })}
        </div>

      </div>

    </div>
  )
}

function Step3({handleSelect,bookingData,carList,addOnList,saveUserData,currency,currencyRate,currencyLoading,updateReservationInfo}){
  const auth = authData();
  const { register, handleSubmit, control, formState: { errors } } = useForm({
    defaultValues:{
      first_name: (bookingData?.userData ? bookingData?.userData?.first_name : (auth ? auth?.customer_details?.first_name:'')),
      last_name: (bookingData?.userData ? bookingData?.userData?.last_name : (auth ? auth?.customer_details?.last_name:'')),
      phone: (bookingData?.userData ? bookingData?.userData?.phone : (auth ? auth?.customer_details?.phone:'')),
      email: (bookingData?.userData ? bookingData?.userData?.email : (auth ? auth?.customer_details?.email:'')),
      address: (bookingData?.userData ? bookingData?.userData?.address : (auth ? auth?.customer_details?.address:'')),
      city: (bookingData?.userData ? bookingData?.userData?.city : (auth ? auth?.customer_details?.city:'')),
      state: (bookingData?.userData ? bookingData?.userData?.state : (auth ? auth?.customer_details?.state:'')),
      zip: (bookingData?.userData ? bookingData?.userData?.zip : (auth ? auth?.customer_details?.zipcode:'')),
      age: (bookingData?.userData ? bookingData?.userData?.age : (auth ? auth?.customer_details?.age:'')),
      no_of_people: (bookingData?.userData ? bookingData?.userData?.no_of_people : ''),
    }
  });
  const navigate = useNavigate();

  const onSubmit = (data) => {
    saveUserData(data);
  };

  const goToLogin = ()=>{
    localStorage.setItem('reDirectUrl', '/reservation');
    navigate('/sign-in');
  }
  
  return (
    <React.Fragment>
      <div className={`${styles.SelectCarSec}`}>

      <LeftPanelBlock handleSelect={handleSelect} bookingData={bookingData} carList={carList} addOnList={addOnList} currency={currency} currencyRate={currencyRate} currencyLoading={currencyLoading} currentStep={3} updateReservationInfo={updateReservationInfo} />

      <div className={`${styles.AddonsBigSide}`}>
        
        
        <form id="hook-form" onSubmit={handleSubmit(onSubmit)}>
          
          <div className={`${styles.ClientDetailsForm}`}>
            <div className={`${styles.FormHead}`}>
              <h2 className={`${styles.BookingFormTitle}`}>Customer Information</h2>
              {auth ? <p className={`${styles.LoginFormSTextTwo}`}>Logged in as <Link>{auth?.name}</Link></p>:<p className={`${styles.LoginFormSTextTwo}`}>Already have an account? <Link onClick={goToLogin}>LogIn</Link></p>}
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroup}`}>
                <label class={`${styles.FormLabel}`}>First Name*</label>
                <input type="text" {...register("first_name", { required: true })}  className={`${styles.FormControl}`} />
                {errors?.first_name?.type === "required" && <span className={`${styles.ErrorM}`}>This field is required</span>}
              </div>
              <div className={`${styles.FormGroup}`}>
                <label class={`${styles.FormLabel}`}>Last Name*</label>
                <input type="text" {...register("last_name", { required: true })} className={`${styles.FormControl}`} />
                {errors?.last_name?.type === "required" && <span className={`${styles.ErrorM}`}>This field is required</span>}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroup}`}>
                <label class={`${styles.FormLabel}`}>Phone Number*</label>
                <Controller
                    control={control}
                    name="phone"
                    {...register("phone", { required: true})}
                    className={`${styles.FormControl}`}
                    render={({ field: { onChange, name, value } }) => (
                      <PatternFormat
                        format="+############"
                        name={name}
                        value={value}
                        onChange={onChange}
                        className={`${styles.FormControl}`}
                      />
                    )}
                  />
                {errors?.phone?.type === "required" && <span className={`${styles.ErrorM}`}>This field is required</span>}
              </div>
              <div className={`${styles.FormGroup}`}>
                <label class={`${styles.FormLabel}`}>Email*</label>
                <input type="email" {...register("email", {
                  required: "This field is required",
                  pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Invalid email address"
                  }
                })} className={`${styles.FormControl}`} readOnly={auth && auth?.role === 2} />

                {errors?.email && <span className={`${styles.ErrorM}`}>{errors.email?.message}</span>}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroupFull}`}>
                <label class={`${styles.FormLabel}`}>Address*</label>
                <input type="text" {...register("address", { required: true })} className={`${styles.FormControl}`} />
                {errors?.address?.type === "required" && <span className={`${styles.ErrorM}`}>This field is required</span>}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroupOneThird}`}>
                <label class={`${styles.FormLabel}`}>Country/State*</label>
                <input type="text" {...register("state", { required: true })} className={`${styles.FormControl}`} />
                {errors?.state?.type === "required" && <span className={`${styles.ErrorM}`}>This field is required</span>}
              </div>
              <div className={`${styles.FormGroupOneThird}`}>
                <label class={`${styles.FormLabel}`}>City*</label>
                <input type="text" {...register("city", { required: true })} className={`${styles.FormControl}`} />
                {errors?.city?.type === "required" && <span className={`${styles.ErrorM}`}>This field is required</span>}
              </div>
              <div className={`${styles.FormGroupOneThird}`}>
                <label class={`${styles.FormLabel}`}>Zip/Postal Code*</label>
                <Controller
                    control={control}
                    name="zip"
                    {...register("zip", { required: true})}
                    className={`${styles.FormControl}`}
                    render={({ field: { onChange, name, value } }) => (
                      <PatternFormat
                        format="######"
                        name={name}
                        value={value}
                        onChange={onChange}
                        className={`${styles.FormControl}`}
                      />
                    )}
                  />
                {errors?.zip?.type === "required" && <span className={`${styles.ErrorM}`}>This field is required</span>}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroup}`}>
                <label class={`${styles.FormLabel}`}>Number of Passenger(s)*</label>
                <Controller
                    control={control}
                    name="no_of_people"
                    {...register("no_of_people", { required: true,min: 0})}
                    className={`${styles.FormControl}`}
                    render={({ field: { onChange, name, value } }) => (
                      <NumericFormat
                        isAllowed={(values, sourceInfo) => {
                          const { value } = values;
                          return value < 100;
                        }}
                        name={name}
                        value={value}
                        onChange={onChange}
                        className={`${styles.FormControl}`}
                      />
                    )}
                  />
                {errors?.no_of_people?.type === "required" && <span className={`${styles.ErrorM}`}>This field is required</span>}
                {errors?.no_of_people?.type === "min" && <span className={`${styles.ErrorM}`}>Number of Passenger(s) should be grater than 0</span>}
              </div>
              <div className={`${styles.FormGroup}`}>
                <label class={`${styles.FormLabel}`}>Driver's Age*</label>
                <Controller
                    control={control}
                    name="age"
                    {...register("age", { required: true,min: 25})}
                    className={`${styles.FormControl}`}
                    render={({ field: { onChange, name, value } }) => (
                      <NumericFormat
                        isAllowed={(values, sourceInfo) => {
                          const { value } = values;
                          return value < 100;
                        }}
                        name={name}
                        value={value}
                        onChange={onChange}
                        className={`${styles.FormControl}`}
                      />
                    )}
                  />
                {errors?.age?.type === "required" && <span className={`${styles.ErrorM}`}>This field is required</span>}
                {errors?.age?.type === "min" && <span className={`${styles.ErrorM}`}>Driver's Age should be grater than 24</span>}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroup}`}>
                <label class={`${styles.FormLabel}`}>Arrival Airline Carrier*</label>
                <input type="text" {...register("airline_carrier", { required: true })} className={`${styles.FormControl}`} />
                {errors?.airline_carrier?.type === "required" && <span className={`${styles.ErrorM}`}>This field is required</span>}
              </div>
              <div className={`${styles.FormGroup}`}>
                <label class={`${styles.FormLabel}`}>Arrival Vessel Number*</label>
                <input type="text" {...register("vessel_number", { required: true })} className={`${styles.FormControl}`} />
                {errors?.vessel_number?.type === "required" && <span className={`${styles.ErrorM}`}>This field is required</span>}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroup}`}>
                <label class={`${styles.FormLabel}`}>Departure Airline Carrier*</label>
                <input type="text" {...register("departure_airline_carrier", { required: true })} className={`${styles.FormControl}`} />
                {errors?.departure_airline_carrier?.type === "required" && <span className={`${styles.ErrorM}`}>This field is required</span>}
              </div>
              <div className={`${styles.FormGroup}`}>
                <label class={`${styles.FormLabel}`}>Departure Vessel Number*</label>
                <input type="text" {...register("departure_vessel_number", { required: true })} className={`${styles.FormControl}`} />
                {errors?.departure_vessel_number?.type === "required" && <span className={`${styles.ErrorM}`}>This field is required</span>}
              </div>
            </div>
          </div>

          
        </form>
      </div>

    </div>
    </React.Fragment>
  )
}

function Step4({handleSelect,bookingData,carList,addOnList,currency,currencyRate,currencyLoading,updateReservationInfo,applyPromoCode,removePromoCode}){
  const [loading, setLoading] = React.useState(false);
  const [userId, setUserId] = React.useState(0);
  const navigate = useNavigate();
  const auth = authData();
  const [checked, setChecked] = React.useState(false);
  const [checked2, setChecked2] = React.useState(false);
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [totalAmountConverted, setTotalAmountConverted] = React.useState(0);


  useEffect(()=>{
    if(auth){
      setUserId(auth?.id);
    }
  },[auth]);
  useEffect(()=>{
    setLoading(false);

    let carAmountTemp = 0;
    let totalAmountTemp = 0;
    let subTotalAmountTemp = 0;
    if(bookingData?.securityDeposit){
      totalAmountTemp += bookingData?.securityDeposit;
    }
    if(bookingData?.cars && bookingData?.cars?.length){
      subTotalAmountTemp += bookingData?.cars[0]?.sub_amount;
      carAmountTemp = bookingData?.cars[0]?.sub_amount;
    }
    if(bookingData?.addOns && bookingData?.addOns?.length){
      addOnList.forEach(item=>{
        let isAddOn = bookingData?.addOns.find(i=> i.addon_id === item.id);
        if(isAddOn){
          subTotalAmountTemp += (bookingData?.days * item?.price * isAddOn?.quantity);
        }
      });
    }
    totalAmountTemp += subTotalAmountTemp;

    let discountAmountTemp = 0;

    let discountAmountTemp2 =0;
    if(bookingData?.promotionalCode?.code){
      let disPercent = bookingData?.promotionalCode?.discount;
      disPercent = parseInt(disPercent);
      if(bookingData?.promotionalCode?.item_type === ''){
        discountAmountTemp2 = (subTotalAmountTemp * disPercent)/100;
      }

      if(bookingData?.promotionalCode?.item_type === 'car'){
        discountAmountTemp2 = (carAmountTemp * disPercent)/100;
      }

      if(bookingData?.promotionalCode?.item_type === 'addOn'){
        let addOnAmoutTemp = 0;
        
        addOnList.forEach(item=>{
          let isAddOn = bookingData?.addOns.find(i=> i.addon_id === item.id);
          if(isAddOn && item.id === bookingData?.promotionalCode?.item_id){
            addOnAmoutTemp += (bookingData?.days * item?.price * isAddOn?.quantity);
          }
        });
        discountAmountTemp2 = (addOnAmoutTemp * disPercent)/100;
      }
      totalAmountTemp -= discountAmountTemp2;
    }else{
      if(bookingData?.isDiscount === 1){
        discountAmountTemp = (subTotalAmountTemp * 10)/100;
        totalAmountTemp -= discountAmountTemp;
      }
    }

    if(bookingData?.days < 7){
      totalAmountTemp += 50;
    }

    let paymentFeeTemp = (((subTotalAmountTemp+50-discountAmountTemp)*3)/100);
    paymentFeeTemp = paymentFeeTemp.toFixed(2);
    paymentFeeTemp = parseFloat(paymentFeeTemp);

    totalAmountTemp += paymentFeeTemp;

    setTotalAmount(totalAmountTemp);



    axios(process.env.REACT_APP_API_URL + 'v1/currency-convertter/WST/USD').then(response => {
      let cRate = response.data.data;
      let totalAmountTemp2 =cRate*totalAmountTemp;
      totalAmountTemp2 = totalAmountTemp2.toFixed(2);
      setTotalAmountConverted(totalAmountTemp2);
    });

  },[bookingData,addOnList]);

   // creates a paypal order
 const createOrder = (data, actions) => {
  return actions.order
    .create({
      purchase_units: [
        {
          description: "Booking on Motu Car Rental",
          amount: {
            currency_code: "USD",
            value: totalAmountConverted
          },
        },
      ],
      // not needed if a shipping address is actually needed
      application_context: {
        shipping_preference: "NO_SHIPPING",
      },
    })
    .then((orderID) => {
      return orderID;
    });
};

// check Approval
const onApprove = (data, actions) => {
  setLoading(true);
  return actions.order.capture().then(function (details) {
    let purchase_units = details.purchase_units;
    purchase_units = purchase_units[0];
    onSubmit(purchase_units?.payments?.captures[0]?.id);
  });
};

const onSubmit = (paymentIdNew) => {
    setLoading(true);
    let totalAmount = 0;
    let subTotalAmount = 0;
    let pCode = '';
    let pCodeId = 0;
    let carAmountTemp = 0;

    if(bookingData?.cars?.length){
      bookingData?.cars.forEach(item=>{
        totalAmount += item.sub_amount;
        subTotalAmount += item.sub_amount;
        carAmountTemp = item.sub_amount;
      });
    }
    if(bookingData?.addOns?.length){
      bookingData?.addOns.forEach(item=>{
        totalAmount += item.sub_amount;
        subTotalAmount += item.sub_amount;
      });
    }
    if(bookingData?.securityDeposit){
      totalAmount += bookingData?.securityDeposit;
    }

    let discountAmnt = 0;

    let discountAmnt2 =0;
    if(bookingData?.promotionalCode?.code){
      pCode = bookingData?.promotionalCode?.code;
      pCodeId = bookingData?.promotionalCode?.id;
      let disPercent = bookingData?.promotionalCode?.discount;
      disPercent = parseInt(disPercent);
      if(bookingData?.promotionalCode?.item_type === ''){
        discountAmnt2 = (subTotalAmount * disPercent)/100;
      }

      if(bookingData?.promotionalCode?.item_type === 'car'){
        discountAmnt2 = (carAmountTemp * disPercent)/100;
      }

      if(bookingData?.promotionalCode?.item_type === 'addOn'){
        let addOnAmoutTemp = 0;
        
        addOnList.forEach(item=>{
          let isAddOn = bookingData?.addOns.find(i=> i.addon_id === item.id);
          if(isAddOn && item.id === bookingData?.promotionalCode?.item_id){
            addOnAmoutTemp += (bookingData?.days * item?.price * isAddOn?.quantity);
          }
        });
        discountAmnt2 = (addOnAmoutTemp * disPercent)/100;
      }
      totalAmount -= discountAmnt2;
    }else{
      if(bookingData?.isDiscount === 1){
        discountAmnt = (subTotalAmount * 10)/100;
        totalAmount -= discountAmnt;
      }
    }

    let dropoffAmnt = 0;
    if(bookingData?.days < 7){
      dropoffAmnt = 50;
      totalAmount += dropoffAmnt;
    }

    let paymentFee = (((subTotalAmount+50-discountAmnt)*3)/100);
    paymentFee = paymentFee.toFixed(2);
    paymentFee = parseFloat(paymentFee);

    totalAmount += paymentFee;

    let postData = {...bookingData,...bookingData.userData,dropoff_location:bookingData?.dropOffLocation,user_id:userId,amount:subTotalAmount,security_amount:bookingData?.securityDeposit,discount_amount:discountAmnt,dropoff_amount:dropoffAmnt,payment_fee:paymentFee,total_amount:totalAmount,payable_amount:(totalAmount*currencyRate),currency:currency,transaction_id:paymentIdNew,pCodeId:pCodeId,pCode:pCode,discountAmnt2:discountAmnt2,total_amount_usd:totalAmountConverted};

    axios.post(process.env.REACT_APP_API_URL+'v1/bookings', postData).then(response => {
      let refId = response?.data?.data?.reference_id;
      setLoading(false);
      localStorage.removeItem("bookingData");
      navigate('/booking-success/'+refId);
    }).catch(error => {
      setLoading(false);
      navigate('/booking-failed');
    });
  };
  const PayCheck= (event) => {
    setChecked(event.target.checked);
  }
  const PayCheck2= (event) => {
    setChecked2(event.target.checked);
  }

  const [promoCode, setPromoCode] = React.useState(bookingData?.promotionalCode?.code);
  const [promoCodeError, setPromoCodeError] = React.useState('');
  const [promoCodeApllied, setPromoCodeApllied] = React.useState((bookingData?.promotionalCode?.code)?true:false);

  const applyCode = ()=>{
    setPromoCodeError('');
    if(promoCode === ''){
      setPromoCodeError('Please enter promo code.');
      return false;
    }
    let addOnIds = '';
    if(bookingData.addOns && bookingData.addOns.length){
      let addOnArr = bookingData.addOns.map(i=>{
        return i.addon_id;
      })
      addOnIds = addOnArr.join(',');
    }
    let postData = {'coupon':promoCode, 'booking_date':bookingData.pickUpDate,car_id:bookingData.cars[0].car_id,addOnIds:addOnIds};
    setLoading(true);
    axios.post(process.env.REACT_APP_API_URL + 'v1/check-coupon-code',postData).then(response => {
      let responseData = response.data;
      if(responseData.type === 'error'){
        setPromoCodeError(responseData.message);
      }
      if(responseData.type === 'success'){
        setPromoCodeError('');
        setPromoCodeApllied(true);

        applyPromoCode(responseData.data);
      }
      setLoading(false);
    });
  }

  const removeCode = ()=>{
    setPromoCodeError('');
    setPromoCodeApllied(false);
    setPromoCode('');
    removePromoCode();
  }
  
  return (
    <React.Fragment>
      {loading && <Loader /> }
      <div className={`${styles.SelectCarSec}`}>

      <LeftPanelBlock handleSelect={handleSelect} bookingData={bookingData} carList={carList} addOnList={addOnList} currency={currency} currencyRate={currencyRate} currencyLoading={currencyLoading} currentStep={4} updateReservationInfo={updateReservationInfo} />

      <div className={`${styles.AddonsBigSide}`}>
        
        <div className={`${styles.ClientDetailsForm}`} style={{paddingBottom:'0',marginBottom:'0'}}>
          <h2 className={`${styles.BookingFormTitle}`}>Payment Information</h2>
          <div className={`${styles.PayableAmmo}`}>
            <p className={`${styles.Text}`}>Payable Amount: <span className={`${styles.Amount}`}>{!currencyLoading && currency+' '+((totalAmount * currencyRate).toFixed(2))}</span></p>
          </div>
        </div>
        <div className={`${styles.ClientDetailsForm}`} style={{width:'100%', maxWidth:'400px'}}>
          <h2 className={`${styles.BookingFormTitle}`} style={{marginBottom:'0'}}>Promotional Code</h2>
          <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroupFull}`} style={{position:'relative'}}>
                <label class={`${styles.FormLabel}`}>Have A Promo Code</label>
                <input type="text" className={`${styles.FormControl}`} value={promoCode} onChange={(e) => {setPromoCode(e.target.value);setPromoCodeError('');}} readOnly={promoCodeApllied} />
                {(!promoCodeApllied) && <span style={{position:'absolute',top:'38px', right:'10px',cursor:'pointer', color:'#008000'}} onClick={applyCode}>Apply</span>}
                {promoCodeApllied && <span style={{position:'absolute',top:'38px', right:'10px',cursor:'pointer', color:'#008000'}} onClick={removeCode}>Remove</span>}
                {(promoCodeError !== '') && <span className={`${styles.ErrorM}`}>{promoCodeError}</span>}
              </div>
            </div>
        </div>
        
        <div className={`${styles.AcceptCheckbox}`}>
          <Checkbox checked={checked} onChange={PayCheck}  />
          <p>Yes, I would like to receive newsletters and special offers by email.</p>
        </div>
        
        <div className={`${styles.AcceptCheckbox}`}>
          <Checkbox checked={checked2} onChange={PayCheck2}  />
          <p>I accept Motu Car Rentals <RouterLink to={'/terms'} target="_blank">Terms</RouterLink> and <RouterLink to={'/privacy'} target="_blank">Privacy Policy</RouterLink></p>
        </div>
        
        {(totalAmount > 0 && checked2 )&& <PayPalButtons style={{ layout: "vertical" }} createOrder={createOrder} onApprove={onApprove} />}        
        
      </div>

    </div>
    </React.Fragment>
  )
}

function SuccessBlock(){
  return (
    <div>
      Thank You:
      All steps completed - you&apos;re finished
    </div>
  )
}


export default function Reservation() {
  const auth = authData();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [bookingData, setBookingData] = React.useState({});
  const [carList, setCarList] = React.useState([]);
  const [addOnList, setAddOnList] = React.useState([]);
  const [currency, setCurrency] = React.useState('');
  const [currencyRate, setCurrencyRate] = React.useState(1);
  const [currencyLoading, setCurrencyLoading] = React.useState(false);
  const [bookingCars, setBookingCars] = React.useState([]);
  const [bookingAddOns, setBookingAddOns] = React.useState([]);
  const [openSign, setOpenSign] = React.useState(false);

  useEffect(()=>{
    function fetchCars(){
      axios(process.env.REACT_APP_API_URL + 'v1/car-list').then(response => {
        let allData = response.data.data;
        setCarList(allData);
      }).catch(error=>{
        setCarList([]);
      });
    }

    function fetchAddons(){
      axios(process.env.REACT_APP_API_URL + 'v1/add-on-list').then(response => {
        let allData = response.data.data;
        setAddOnList(allData);
      }).catch(error=>{
        setAddOnList([]);
      });
    }

    let currentCurrency = localStorage.getItem("currentCurrency");
    if(currentCurrency){
      setCurrency(currentCurrency);
      getCurrencyRate(currentCurrency);
    }else{
      setCurrency('USD');
      getCurrencyRate('USD');
    }

    fetchCars();
    fetchAddons();
  },[]);

  useEffect(()=>{
    let bookingDataTemp = localStorage.getItem("bookingData");
    let startDate = moment().format('YYYY-MM-DD');
    let endDate = moment().format('YYYY-MM-DD');
    if(bookingDataTemp){
      bookingDataTemp = JSON.parse(bookingDataTemp);
      startDate = bookingDataTemp?.pickUpDate;
      endDate = bookingDataTemp?.dropOffDate;
    }
    function fetchBookingCars(){
      axios(process.env.REACT_APP_API_URL + 'v1/booking-car-list/'+startDate+'/'+endDate).then(response => {
        let allData = response.data.data;
        setBookingCars(allData);
      }).catch(error=>{
        setBookingCars([]);
      });
    }

    function fetchBookingAddons(){
      axios(process.env.REACT_APP_API_URL + 'v1/booking-add-on-list/'+startDate+'/'+endDate).then(response => {
        let allData = response.data.data;
        setBookingAddOns(allData);
      }).catch(error=>{
        setBookingAddOns([]);
      });
    }

    if(activeStep === 0){
      fetchBookingCars();
    }
    if(activeStep === 1){
      fetchBookingAddons();
    }
  },[activeStep]);

  useEffect(() => {
    if(!auth){
      setOpenSign(true);
    }
  },[auth]);

  useEffect(() => {
    window.addEventListener("click", handleDocumentClick); 
    return () => window.removeEventListener("click", handleDocumentClick);
  });

  useEffect(() => {
    let bookingDataTemp = localStorage.getItem("bookingData");
    if(bookingDataTemp){
      bookingDataTemp = JSON.parse(bookingDataTemp);
      setBookingData(bookingDataTemp);

      if(bookingDataTemp?.activeStep){
        setActiveStep(bookingDataTemp?.activeStep);
      }
    }else{
      let startDate = moment();
      let endDate = moment().add(1,'d');
      let startTime = moment().set('hour',9).set('minute',0).set('second',0).set('millisecond',0);
      let endTime = moment().set('hour',9).set('minute',0).set('second',0).set('millisecond',0);

      let bookingData = {
        location: 'Faleolo International Airport',
        dropOffLocation: 'Faleolo International Airport',
        pickUpDate: moment(startDate).format('YYYY-MM-DD'),
        dropOffDate: moment(endDate).format('YYYY-MM-DD'),
        pickUpTime: moment(startTime).format('HH:mm:ss'),
        dropOffTime: moment(endTime).format('HH:mm:ss'),
        days: 1,
        activeStep:0
      };

      localStorage.setItem('bookingData', JSON.stringify(bookingData));
      setBookingData(bookingData);
    }
  },[setBookingData]);

  const goToSignUp = ()=>{
    localStorage.setItem('reDirectUrl', '/reservation');
    navigate('/sign-up');
  }
  
  const handleCloseSign = () => {
    setOpenSign(false);
  };

  const handleDocumentClick = (event)=>{
    if(event.target.getAttribute('cngCurrency')){
      let currentCurrency = localStorage.getItem("currentCurrency");
      if(currentCurrency){
        setCurrency(currentCurrency);
        getCurrencyRate(currentCurrency);
      }else{
        setCurrency('USD');
        getCurrencyRate('USD');
      }
    }
  }

  const getCurrencyRate = (selectcurrency)=>{
    setCurrencyLoading(true);
    axios(process.env.REACT_APP_API_URL + 'v1/currency-convertter/WST/'+selectcurrency).then(response => {
      setCurrencyRate(response.data.data);
      setCurrencyLoading(false);
    }).catch(error=>{
      setCurrencyLoading(false);
    });
  }

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    let bookingDataTemp = bookingData;
    bookingDataTemp = {...bookingDataTemp,activeStep:activeStep+1};
    localStorage.setItem('bookingData', JSON.stringify(bookingDataTemp));

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleSelect = (step)=>{
    let bookingDataTemp = bookingData;
    bookingDataTemp = {...bookingDataTemp,activeStep:step};
    localStorage.setItem('bookingData', JSON.stringify(bookingDataTemp));
    setActiveStep(step);
  }

  const handleBack = () => {
    let bookingDataTemp = bookingData;
    bookingDataTemp = {...bookingDataTemp,activeStep:activeStep-1};
    localStorage.setItem('bookingData', JSON.stringify(bookingDataTemp));
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    
    let bookingDataTemp = bookingData;
    bookingDataTemp = {...bookingDataTemp,activeStep:0};
    localStorage.setItem('bookingData', JSON.stringify(bookingDataTemp));
  };

  const applyPromoCode = (item) =>{
    let bookingDataTemp = bookingData;
    let promoObj = {
      id:item.id,
      code:item.code,
      discount:item.discount,
      item_id:item.item_id,
      item_type:item.item_type
    };

    bookingDataTemp = {...bookingDataTemp,promotionalCode:promoObj};
    setBookingData(bookingDataTemp);

    localStorage.setItem('bookingData', JSON.stringify(bookingDataTemp));
  }

  const removePromoCode = () =>{
    let bookingDataTemp = bookingData;
    bookingDataTemp = {...bookingDataTemp,promotionalCode:{}};
    setBookingData(bookingDataTemp);

    localStorage.setItem('bookingData', JSON.stringify(bookingDataTemp));
  }

  const selectCar = (item) =>{
    let bookingDataTemp = bookingData;
    let carObjArr = [{
      car_id:item.id,
      price:item.price,
      sub_amount: item.price*bookingDataTemp.days
    }];
    bookingDataTemp = {...bookingDataTemp,cars:carObjArr,securityDeposit:parseInt(item.security_amount),promotionalCode:{}};
    setBookingData(bookingDataTemp);

    localStorage.setItem('bookingData', JSON.stringify(bookingDataTemp));
  }

  const selectAddOn = (item) =>{

    let bookingDataTemp = bookingData;
    let addOnArr = [];
    if(bookingDataTemp && bookingDataTemp?.addOns){
      addOnArr =  bookingDataTemp.addOns;
    }
    addOnArr.push({addon_id:item.id, price:item.price, sub_amount: item.price*bookingDataTemp.days,quantity:1});
    bookingDataTemp = {...bookingDataTemp,addOns:addOnArr};
    setBookingData(bookingDataTemp);

    localStorage.setItem('bookingData', JSON.stringify(bookingDataTemp));
  }

  const updateAddOn = (item,type)=>{
    let bookingDataTemp = bookingData;
    let addOnArr = [];
    if(bookingDataTemp && bookingDataTemp?.addOns){
      addOnArr =  bookingDataTemp.addOns;
    }
    addOnArr = addOnArr.map(i=>{
      let newItem = i;
      if(item.id === i.addon_id){
        if(type === 'add'){
          let newQuantity = (i.quantity + 1);
          newItem = {...newItem,quantity: newQuantity, sub_amount: item.price*bookingDataTemp.days*newQuantity}
        }
        if(type === 'sub'){
          let newQuantity = (i.quantity - 1);
          newItem = {...newItem,quantity: newQuantity, sub_amount: item.price*bookingDataTemp.days*newQuantity}
        }
      }
      return newItem;
    });

    bookingDataTemp = {...bookingDataTemp,addOns:addOnArr};
    setBookingData(bookingDataTemp);

    localStorage.setItem('bookingData', JSON.stringify(bookingDataTemp));
  }

  const removeAddOn = (item) =>{
    let bookingDataTemp = bookingData;
    let addOnArr = [];
    if(bookingDataTemp && bookingDataTemp?.addOns){
      addOnArr =  bookingDataTemp.addOns;
    }

    if(addOnArr.length){
      let addOnArrTemp = addOnArr.filter((i)=>{
        return (i.addon_id !== item.id);
      });

      bookingDataTemp = {...bookingDataTemp,addOns:addOnArrTemp};
      setBookingData(bookingDataTemp);
      localStorage.setItem('bookingData', JSON.stringify(bookingDataTemp));
    }
  }

  const updateReservationInfo = (item)=>{
    let bookingDataTemp = bookingData;
    let startDate = moment(item.startDate).format('YYYY-MM-DD')+' '+moment(item.startTime).format('HH:mm:ss');
    let endDate = moment(item.endDate).format('YYYY-MM-DD')+' '+moment(item.endTime).format('HH:mm:ss');
    let dayDiff = moment(endDate,'YYYY-MM-DD HH:mm:ss').diff(moment(startDate,'YYYY-MM-DD HH:mm:ss'),'minutes');
    let dayDiff2 = moment(item.startDate).diff(moment(),'days');
    dayDiff = (dayDiff/(24*60));
    if(dayDiff > parseInt(dayDiff)){
      dayDiff = parseInt(dayDiff)+1;
    }
    if(dayDiff < 1){
      dayDiff = 1;
    }
    //bookingDataTemp = {...bookingDataTemp, location: item.location?.value, dropOffLocation: item.dropOffLocation?.value, pickUpDate: moment(item.startDate).format('YYYY-MM-DD'), dropOffDate: moment(item.endDate).format('YYYY-MM-DD'), pickUpTime: moment(item.startTime).format('HH:mm:ss'), dropOffTime: moment(item.endTime).format('HH:mm:ss'), days: dayDiff, isDiscount: (dayDiff2 >= 9)?1:0};

    bookingDataTemp = {
      location: item.location?.value, 
      dropOffLocation: item.dropOffLocation?.value, 
      pickUpDate: moment(item.startDate).format('YYYY-MM-DD'), 
      dropOffDate: moment(item.endDate).format('YYYY-MM-DD'), 
      pickUpTime: moment(item.startTime).format('HH:mm:ss'), 
      dropOffTime: moment(item.endTime).format('HH:mm:ss'), 
      days: dayDiff, 
      isDiscount: (dayDiff2 >= 9)?1:0,
      discountAmount:0,
      activeStep:0
    };

    /*if(bookingDataTemp?.cars){
      let carObjArrOld = bookingDataTemp.cars[0];
      let carObjArr = [{
        car_id: carObjArrOld.car_id,
        price: carObjArrOld.price,
        sub_amount: carObjArrOld.price*dayDiff
      }];
      bookingDataTemp = {...bookingDataTemp,cars:carObjArr};
    }*/

    setBookingData(bookingDataTemp);

    localStorage.setItem('bookingData', JSON.stringify(bookingDataTemp));

    setActiveStep(0);
    setSkipped(new Set());
    
    axios(process.env.REACT_APP_API_URL + 'v1/booking-car-list/'+moment(startDate).format('YYYY-MM-DD')+'/'+moment(endDate).format('YYYY-MM-DD')).then(response => {
      let allData = response.data.data;
      setBookingCars(allData);
    }).catch(error=>{
      setBookingCars([]);
    });
    
    axios(process.env.REACT_APP_API_URL + 'v1/booking-add-on-list/'+moment(startDate).format('YYYY-MM-DD')+'/'+moment(endDate).format('YYYY-MM-DD')).then(response => {
      let allData = response.data.data;
      setBookingAddOns(allData);
    }).catch(error=>{
      setBookingAddOns([]);
    });
  
  }

  const saveUserData = (data) =>{
    let bookingDataTemp = bookingData;
    bookingDataTemp = {...bookingDataTemp,userData:data};
    setBookingData(bookingDataTemp);
    //localStorage.setItem('bookingData', JSON.stringify(bookingDataTemp));
    //handleNext();

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    bookingDataTemp = {...bookingDataTemp,activeStep:activeStep+1};
    localStorage.setItem('bookingData', JSON.stringify(bookingDataTemp));

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  }

  return (
      <React.Fragment>

        <div className="InnerBanner">
          <Parallax bgImage="/images/reservation-banner.jpg" strength={400}>
            <div style={{ height: 450 }} className={`${styles.BannerConArea}`}>
              <div className='container'>
                <div className={`${styles.InnerBannerRow}`}>
                  <h2 className={`${styles.InnerBannerTitle}`}>Reservation</h2>
                </div>
              </div>
            </div>
          </Parallax>
        </div>

        <div className='ReservationSec'>
          <div className='container'>
            <Box sx={{ width: '100%' }}>
              <Stepper activeStep={activeStep} className="ReservationStepper">
                {steps?.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  if (isStepOptional(index)) {
                    labelProps.optional = (
                      <Typography variant="caption"></Typography>
                    );
                  }
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <SuccessBlock />
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button onClick={handleReset}>Reset</Button>
                  </Box>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {(activeStep ===0) && <Step1 handleSelect={handleSelect} bookingData={bookingData} carList={carList} addOnList={addOnList} selectCar={selectCar} currency={currency} currencyRate={currencyRate} currencyLoading={currencyLoading} bookingCars={bookingCars} updateReservationInfo={updateReservationInfo} />}
                  {(activeStep ===1) && <Step2 handleSelect={handleSelect} bookingData={bookingData} carList={carList} addOnList={addOnList} selectAddOn={selectAddOn} updateAddOn={updateAddOn} removeAddOn={removeAddOn} currency={currency} currencyRate={currencyRate} currencyLoading={currencyLoading} bookingAddOns={bookingAddOns} updateReservationInfo={updateReservationInfo} />}
                  {(activeStep ===2) && <Step3 handleSelect={handleSelect} bookingData={bookingData} carList={carList} addOnList={addOnList} saveUserData={saveUserData} currency={currency} currencyRate={currencyRate} currencyLoading={currencyLoading} updateReservationInfo={updateReservationInfo} />}
                  {(activeStep ===3) && <Step4 handleSelect={handleSelect} bookingData={bookingData} carList={carList} addOnList={addOnList} currency={currency} currencyRate={currencyRate} currencyLoading={currencyLoading} updateReservationInfo={updateReservationInfo} applyPromoCode={applyPromoCode} removePromoCode={removePromoCode} />}
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} className="StepperBuSec">
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                      className="TrendingBUBrown"
                    >
                      Back
                    </Button>

                    {/*(activeStep === (steps.length - 1)) && <Button type="submit" form="hook-form" className="TrendingBUGreen" >Finish</Button>*/}

                    {(activeStep === 2) && <Button type="submit" form="hook-form" className="TrendingBUGreen">SECURE PAYMENT</Button>}

                    {(activeStep === 0) && <Button onClick={handleNext} className="TrendingBUGreen" disabled={ !(bookingData?.cars && bookingData?.cars?.length && !(bookingCars.indexOf(bookingData?.cars[0]?.car_id) > -1))}>Next</Button>}
                    {(activeStep === 1) && <Button onClick={handleNext} className="TrendingBUGreen" disabled={(bookingData?.addOns?.some(r=> bookingAddOns.indexOf(r.addon_id) >= 0))}>Next</Button>}

                  </Box>
                </React.Fragment>
              )}
            </Box>

            

          </div>
        </div>
        
        <Dialog
        maxWidth="sm"
        open={openSign}
        onClose={handleCloseSign}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div className={`${styles.ImportantInfoSec}`}>
            <p className={`${styles.ImportantInfoTitle}`}>Please Signup to continue</p>
            <button className={`${styles.SignUpGuestBU}`} onClick={()=>setOpenSign(false)}>Continue as a guest</button>
            <p className={`${styles.PopOrText}`}><span>or</span></p>
            <button className={`${styles.SignUpBUPopup}`} onClick={goToSignUp}>Signup</button>
          </div>
        </DialogContent>
      </Dialog>

      
      </React.Fragment>
  )
}
