import React from 'react';
import styles from './myprofile.module.css';
import withCustomerAuth from "../../components/withCustomerAuth";
import {authData} from "../../../admin/components/getAuth";
import {  Link as RouterLink } from "react-router-dom";


function MyProfile() {
  const auth = authData();

  return (

    <div className={`${styles.MainDivCustomer}`}>
      <img src="/images/backgroundGra.png" alt="" className={`${styles.BackGroundGra}`} />
      <div className='container'>
        <div className={`${styles.UserProCard}`}>
          <div className={`${styles.UserHead}`}>
            <p className={`${styles.UserProName}`}>{auth?.name}</p>
            <RouterLink className={`${styles.SideEditBU}`} to={'/customer/update-profile'}>Edit</RouterLink>
          </div>
          <div className={`${styles.UserProDe}`}>
            <p className={`${styles.UserProDeList}`}><span>Name:</span> {auth?.name}</p>
            <p className={`${styles.UserProDeList}`}><span>Phone No.:</span> {auth?.customer_details?.phone}</p>
            <p className={`${styles.UserProDeList}`}><span>Email:</span> {auth?.email}</p>
            <p className={`${styles.UserProDeList}`}><span>Address:</span> {auth?.customer_details?.address}</p>
            <p className={`${styles.UserProDeList}`}><span>State:</span> {auth?.customer_details?.state}</p>
            <p className={`${styles.UserProDeList}`}><span>City:</span> {auth?.customer_details?.city}</p>
            <p className={`${styles.UserProDeList}`}><span>Zipcode:</span> {auth?.customer_details?.zipcode}</p>
            <p className={`${styles.UserProDeList}`}><span>Age:</span> {auth?.customer_details?.age}</p>
          </div>
        </div>
      </div>
    </div>

  );
}

export default withCustomerAuth(MyProfile);