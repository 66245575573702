import React from 'react';
import styles from './header.module.css';
import { Bell } from 'react-feather';

class Header extends React.Component {

  render() {
    return (
      <header className={`${styles.HeaderMain}`}>
        <div className={`${styles.HeaderMainUnder}`}>
          <div className={`${styles.HeaderRow}`}>

            <div className={`${styles.HeadLogoAdmin}`}><img src="/images/moto-logo.svg" alt="" /></div>

            <div className={`${styles.HeadRightSec}`}>
              <button className={`${styles.HeadBU}`}>
                <Bell/>
                <span className={`${styles.HeadBUCount}`}>9</span>
              </button>
            </div>

          </div>
        </div>
      </header>
  )}
}

export default Header;