import React, { useState } from 'react';
import { ChevronDown, Sliders, Truck, User,FilePlus,LogOut,HelpCircle,Users,Book, Layout,CreditCard, CheckCircle, Star, Calendar } from 'react-feather';
import { Link } from '@mui/material';
import {  Link as RouterLink,useNavigate } from "react-router-dom";
import styles from './footer.module.css';
import {authData} from "../components/getAuth";

export default function Footer() {
  const auth = authData();
  const [showCarDropdownMenu, setShowCarDropdownMenu] = useState(false);
  const [showAddOnDropdownMenu, setShowAddOnDropdownMenu] = useState(false);
  const [showUserDropdownMenu, setShowUserDropdownMenu] = useState(false);
  const [showFAQDropdownMenu, setShowFAQDropdownMenu] = useState(false);
  const [showBlogDropdownMenu, setShowBlogDropdownMenu] = useState(false);
  const [showPageManagerDropdownMenu, setShowPageManagerDropdownMenu] = useState(false);
  const [showTestiDropdownMenu, setShowTestiDropdownMenu] = useState(false);
  

  const navigate = useNavigate();


  const logout = ()=>{
    localStorage.removeItem('authData');
    navigate('/');
  }


    return (
      <footer className={`${styles.FooterMain}`}>
        
        <div className={`${styles.SideAvatarMain}`}>
          <div className={`${styles.SideAvatarMainUnder}`}>

            <div className={`${styles.SideAvatarSec}`}>
              <div className={`${styles.SideAvatarSecUnder}`}>
                <div className={`${styles.SideAvatar}`}><User/></div>
                <span className={`${styles.UserActiveState}`}></span>
              </div>
            </div>

            <div className={`${styles.SideUserName}`}>
              <span>{auth?.name}</span>
            </div>

          </div>
        </div>

        <div className={`${styles.SideMenuLinkSec}`}>
          <div className={`${styles.SideMenuLinkSecUnder}`}>
            <ul>

            <li>
                <RouterLink to={'/admin/dashboard'}>
                  <Sliders/>
                  <p className={`${styles.SideLinkText}`}>Dashboard</p>
                </RouterLink>
              </li>
              <li>
                <RouterLink to={'/admin/quick-booking'}>
                  <CheckCircle/>
                  <p className={`${styles.SideLinkText}`}>Quick Booking</p>
                </RouterLink>
              </li>
              <li onClick={() => setShowCarDropdownMenu(v => !v)} className={`${styles.DropDownSec} ${showCarDropdownMenu ? styles.Open : ''}`}>
                <Link>
                  <Truck/>
                  <p className={`${styles.SideLinkText}`}>Cars</p>
                  <ChevronDown className={`${styles.DownIndi}`} />
                </Link>
                <ul className={`${styles.DropDownList}`}>
                  <li>
                    <RouterLink to={'/admin/car/list'}>List</RouterLink>
                  </li>
                  <li>
                    <RouterLink to={'/admin/car/add'}>Add</RouterLink>
                  </li>
                </ul>
              </li>
              
              <li onClick={() => setShowFAQDropdownMenu(v => !v)} className={`${styles.DropDownSec} ${showFAQDropdownMenu ? styles.Open : ''}`}>
                <Link>
                  <FilePlus/>
                  <p className={`${styles.SideLinkText}`}>AddOns</p>
                  <ChevronDown className={`${styles.DownIndi}`} />
                </Link>
                <ul className={`${styles.DropDownList}`}>
                  <li>
                    <RouterLink to={'/admin/add-on/list'}>List</RouterLink>
                  </li>
                  <li>
                    <RouterLink to={'/admin/add-on/add'}>Add</RouterLink>
                  </li>
                </ul>
              </li>
              <li onClick={() => setShowAddOnDropdownMenu(v => !v)} className={`${styles.DropDownSec} ${showAddOnDropdownMenu ? styles.Open : ''}`}>
                <Link>
                  <HelpCircle/>
                  <p className={`${styles.SideLinkText}`}>FAQ</p>
                  <ChevronDown className={`${styles.DownIndi}`} />
                </Link>
                <ul className={`${styles.DropDownList}`}>
                  <li>
                    <RouterLink to={'/admin/faq/list'}>List</RouterLink>
                  </li>
                  <li>
                    <RouterLink to={'/admin/faq/add'}>Add</RouterLink>
                  </li>
                </ul>
              </li>
              <li onClick={() => setShowUserDropdownMenu(v => !v)} className={`${styles.DropDownSec} ${showUserDropdownMenu ? styles.Open : ''}`}>
                <Link>
                  <User/>
                  <p className={`${styles.SideLinkText}`}>Admins</p>
                  <ChevronDown className={`${styles.DownIndi}`} />
                </Link>
                <ul className={`${styles.DropDownList}`}>
                  <li>
                    <RouterLink to={'/admin/user/list'}>List</RouterLink>
                  </li>
                  <li>
                    <RouterLink to={'/admin/user/add'}>Add</RouterLink>
                  </li>
                </ul>
              </li>
              <li onClick={() => setShowBlogDropdownMenu(v => !v)} className={`${styles.DropDownSec} ${showBlogDropdownMenu ? styles.Open : ''}`}>
                <Link>
                  <CreditCard/>
                  <p className={`${styles.SideLinkText}`}>Coupon</p>
                  <ChevronDown className={`${styles.DownIndi}`} />
                </Link>
                <ul className={`${styles.DropDownList}`}>
                  <li>
                    <RouterLink to={'/admin/coupon/list'}>List</RouterLink>
                  </li>
                  <li>
                    <RouterLink to={'/admin/coupon/add'}>Add</RouterLink>
                  </li>
                </ul>
              </li>
              <li onClick={() => setShowTestiDropdownMenu(v => !v)} className={`${styles.DropDownSec} ${showTestiDropdownMenu ? styles.Open : ''}`}>
                <Link>
                  <Star />
                  <p className={`${styles.SideLinkText}`}>Testimonial</p>
                  <ChevronDown className={`${styles.DownIndi}`} />
                </Link>
                <ul className={`${styles.DropDownList}`}>
                  <li>
                    <RouterLink to={'/admin/testimonial/list'}>List</RouterLink>
                  </li>
                  <li>
                    <RouterLink to={'/admin/testimonial/add'}>Add</RouterLink>
                  </li>
                </ul>
              </li>
              <li>
                <RouterLink to={'/admin/customer/list'}>
                  <Users/>
                  <p className={`${styles.SideLinkText}`}>Customers</p>
                </RouterLink>
              </li>
              <li>
                <RouterLink to={'/admin/booking/list'}>
                  <Book/>
                  <p className={`${styles.SideLinkText}`}>Booking</p>
                </RouterLink>
              </li>
              <li>
                <RouterLink to={'/admin/booking-calendar'}>
                  <Calendar />
                  <p className={`${styles.SideLinkText}`}>Booking Calendar</p>
                </RouterLink>
              </li>
              <li onClick={() => setShowPageManagerDropdownMenu(v => !v)} className={`${styles.DropDownSec} ${showPageManagerDropdownMenu ? styles.Open : ''}`}>
                <Link>
                  <Layout/>
                  <p className={`${styles.SideLinkText}`}>Page Manager</p>
                  <ChevronDown className={`${styles.DownIndi}`} />
                </Link>
                <ul className={`${styles.DropDownList}`}>
                  <li>
                    <RouterLink to={`/admin/page-manager/about`}>About Us</RouterLink>
                  </li>
                  <li>
                    <RouterLink to={`/admin/page-manager/car`}>Cars & Services</RouterLink>
                  </li>
                  <li>
                    <RouterLink to={`/admin/page-manager/contact`}>Contact Us</RouterLink>
                  </li>
                  <li>
                    <RouterLink to={`/admin/page-manager/terms-use`} >Terms Use</RouterLink>
                  </li>
                  <li>
                    <RouterLink to={`/admin/page-manager/privacy-policy`}>Privacy Policy</RouterLink>
                  </li>
                  <li>
                    <RouterLink to={`/admin/page-manager/cancellation-policy`}>Cancellation Policy</RouterLink>
                  </li>
                </ul>
              </li>
              <li>
                <Link onClick={logout}>
                  <LogOut/>
                  <p className={`${styles.SideLinkText}`}>Logout</p>
                </Link>
              </li>

            </ul>
          </div>  
        </div>

      </footer>
  );
}