import React from 'react';
import styles from './home.module.css';
import { Parallax } from 'react-parallax';
import Fade from 'react-reveal/Fade';
import { Calendar, Check, Clock, MapPin, Star, X } from 'react-feather';
//import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Select from "react-select";
import DatePicker from "react-datepicker";
import axios from 'axios';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import withNavigateHook from '../../../customer/components/withNavigateHook';

import Carousel from "react-multi-carousel";
import { Rating } from '@mui/material';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet2: {
    breakpoint: { max: 767, min: 300 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

class Home extends React.Component {
  constructor(props){
    super(props);
    this.state={
      isBoxFixed:false,
      location: null,
      locationError: false,
      dropOffLocation: null,
      dropOffLocationError: false,
      startDate: moment().toDate(),
      startTime: moment().set('hour',9).set('minute',0).set('second',0).set('millisecond',0).toDate(),
      endDate: moment().add(1,'d').toDate(),
      endTime: moment().set('hour',9).set('minute',0).set('second',0).set('millisecond',0).toDate(),
      carList:[],
      currency:'',
      currencyRate:1,
      currencyLoading:false,
      isDiscount:0,
      isOfferShow:true,
      isShowAllCoupons:false,
      showedCoupon:{},
      coupons:[],
      testimonials:[],
    };
    this.setBoxFixed = this.setBoxFixed.bind(this);
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
  }

  setBoxFixed(){
    window.scrollY > 500 ? this.setState({isBoxFixed:true}) : this.setState({isBoxFixed:false});
  }

  handleDocumentClick(event){
    if(event.target.getAttribute('cngCurrency')){
      let currentCurrency = localStorage.getItem("currentCurrency");
      if(currentCurrency){
        this.setState({...this.state, currency: currentCurrency});
        this.getCurrencyRate(currentCurrency);
      }else{
        this.setState({...this.state, currency: 'USD'});
        this.getCurrencyRate('USD');
      }
    }
  }

  componentWillMount(){
    window.addEventListener('scroll',this.setBoxFixed);
    window.addEventListener('click', this.handleDocumentClick);
      let bookingData = localStorage.getItem("bookingData");
      if(bookingData){
        let bookingDataArr= JSON.parse(bookingData);
        let pickUpTime = moment().format('YYYY-MM-DD')+' '+bookingDataArr.pickUpTime;
        let dropOffTime = moment().format('YYYY-MM-DD')+' '+bookingDataArr.dropOffTime;
        this.setState({...this.state, location: {value:bookingDataArr.location,label:bookingDataArr.location}, dropOffLocation: {value:bookingDataArr.dropOffLocation,label:bookingDataArr.dropOffLocation}, startDate: moment(bookingDataArr.pickUpDate).toDate(), endDate: moment(bookingDataArr.dropOffDate).toDate(), startTime: moment(pickUpTime).toDate(), endTime: moment(dropOffTime).toDate(),isDiscount:bookingDataArr.isDiscount});

        let todayDate = moment();
        if (!todayDate.isBefore(moment(bookingDataArr.pickUpDate))) {
          this.setState({...this.state, startDate: moment().toDate()});
        }
      }
      axios(process.env.REACT_APP_API_URL + 'v1/car-list').then(response => {
        let allData = response.data.data;
        this.setState({...this.state, carList: allData});
      }).catch(error=>{
        this.setState({...this.state, carList: []});
      });


      let currentCurrency = localStorage.getItem("currentCurrency");
      if(currentCurrency){
        setTimeout(()=>{
          this.setState({...this.state, currency: currentCurrency});
          this.getCurrencyRate(currentCurrency);
        },500);
      }else{
        setTimeout(()=>{
          this.setState({...this.state, currency: 'USD'});
          this.getCurrencyRate('USD');
        },500);
      }

      axios(process.env.REACT_APP_API_URL + 'v1/coupon-list').then(response => {
        let couponData = response.data.data;
        let coupon1 = couponData[0];
        couponData = couponData.slice(1);
        this.setState({...this.state, coupons: couponData,showedCoupon:coupon1});
      }).catch(error=>{
        this.setState({...this.state, coupons: []});
      });

      axios(process.env.REACT_APP_API_URL + 'v1/testimonial-list').then(response => {
        this.setState({...this.state, testimonials: response.data.data});
      }).catch(error=>{
        this.setState({...this.state, testimonials: []});
      });
      
    }
  componentWillUnmount(){
       window.removeEventListener('scroll',this.setBoxFixed);
       window.removeEventListener('click', this.handleDocumentClick);
  }

  

  changeAirport(obj){
    this.setState({...this.state, location: obj, locationError: false});
  }

  changeDropOffLocation(obj){
    this.setState({...this.state, dropOffLocation: obj, dropOffLocationError: false});
  }

  getCurrencyRate(selectcurrency){
    this.setState({...this.state,currency:selectcurrency,currencyLoading:true});
    axios(process.env.REACT_APP_API_URL + 'v1/currency-convertter/WST/'+selectcurrency).then(response => {
      this.setState({...this.state, currencyRate:response.data.data,currencyLoading:false});
    }).catch(error=>{
      this.setState({...this.state,currencyLoading:false});
    });
  }

  setStartDate(event,date,type){
    if(type === 'startDate'){
      let dayDiff2 = moment(date).diff(moment(),'days');
      let endDate = new Date(new Date(date).setDate(new Date(date).getDate() + 1));
      if(dayDiff2 > 9){
        this.setState({...this.state, startDate: date, endDate: endDate,isDiscount:1});
      }else{
        this.setState({...this.state, startDate: date, endDate: endDate,isDiscount:0});
      }
    }
    if(type === 'endDate'){
      this.setState({...this.state, endDate: date});
    }
    if(type === 'startTime'){
      this.setState({...this.state, startTime: date});
    }
    if(type === 'endTime'){
      this.setState({...this.state, endTime: date});
    }
  }

  addBooking(){
    if(this.state.location && this.state.dropOffLocation){
      let startDate = moment(this.state.startDate).format('YYYY-MM-DD')+' '+moment(this.state.startTime).format('HH:mm:ss');
      let endDate = moment(this.state.endDate).format('YYYY-MM-DD')+' '+moment(this.state.endTime).format('HH:mm:ss');
      let dayDiff = moment(endDate,'YYYY-MM-DD HH:mm:ss').diff(moment(startDate,'YYYY-MM-DD HH:mm:ss'),'minutes');
      let dayDiff2 = moment(this.state.startDate).diff(moment(),'days');
      dayDiff = (dayDiff/(24*60));
      if(dayDiff > parseInt(dayDiff)){
        dayDiff = parseInt(dayDiff)+1;
      }
      if(dayDiff < 1){
        dayDiff = 1;
      }
      if(dayDiff2 >= 9){
        this.setState({...this.state, locationError: false, dropOffLocationError: false,isDiscount:1});
      }else{
        this.setState({...this.state, locationError: false, dropOffLocationError: false,isDiscount:0});
      }

      let bookingData = {
        location: this.state.location?.value,
        dropOffLocation: this.state.dropOffLocation?.value,
        pickUpDate: moment(this.state.startDate).format('YYYY-MM-DD'),
        dropOffDate: moment(this.state.endDate).format('YYYY-MM-DD'),
        pickUpTime: moment(this.state.startTime).format('HH:mm:ss'),
        dropOffTime: moment(this.state.endTime).format('HH:mm:ss'),
        days: dayDiff,
        isDiscount: (dayDiff2 >= 9)?1:0,
        discountAmount:0,
        activeStep:0
      };
      localStorage.setItem('bookingData', JSON.stringify(bookingData));
      this.props.navigation('/reservation');
    }else{
      if(!this.state.location){
        this.setState({...this.state, locationError: true});
      }
      if(!this.state.dropOffLocation){
        this.setState({...this.state, dropOffLocationError: true});
      }
    }
  }

  goToBookNow(){
    this.props.navigation('/reservation');
  }

  showAllCoupons(){
    this.setState({...this.state, isShowAllCoupons: true});
  }
  

  render() {
    const style = {
      control: base => ({
        ...base,
        border: 0,
        // This line disable the blue border
        boxShadow: "none"
      })
    };
    return (
      <React.Fragment>
        <div className='Banner'>

        {(this.state.isOfferShow && this.state.showedCoupon?.discount) && <div className={`${styles.OfferSec}`}>
          <p className={`${styles.OfferTitle}`}>
            <span className={`${styles.Span2}`}>SAVE {this.state.showedCoupon?.discount}%</span>
          </p>
          {this.state.showedCoupon?.code && <p className={`${styles.OfferCouponText}`}>Coupon: <span className={`${styles.OfferCouponBold}`}>{this.state.showedCoupon?.code}</span></p>}
          <p className={`${styles.OfferText}`}>{this.state.showedCoupon?.description}</p>
          {(this.state.coupons.length > 0 && !this.state.isShowAllCoupons) && <p className={`${styles.OfferText} ${styles.OfferTextClick}`} onClick={this.showAllCoupons.bind(this)}>See all offers</p>}
          {this.state.isShowAllCoupons && <>
            {this.state.coupons.map((item,index)=>{
              return (<>
                <p className={`${styles.OfferDiv}`}></p>
                <p className={`${styles.OfferTitle}`}>
                  <span className={`${styles.Span2}`}>SAVE {item?.discount}%</span>
                </p>
                {item?.code && <p className={`${styles.OfferCouponText}`}>Coupon: <span className={`${styles.OfferCouponBold}`}>{item?.code}</span></p>}
                <p className={`${styles.OfferText}`}>{item?.description}</p>
              </>)
            })}
          </>}
          <button className={`${styles.OfferSecCloseBU}`} onClick={()=>this.setState({...this.state,isOfferShow:false})}><X/></button>
        </div>}

          <Parallax bgImage="/images/HomeBanner.jpg" strength={400}>
            <div style={{ height: 850 }} className={`${styles.BannerConArea} ${styles.Top}`}>
              <div className='container'>
                <div className={`${styles.BannerContent}`}>
                  <div className={`${styles.BannerConText}`}>
                    <Fade bottom><p className={`${styles.BannerConTextB}`}>MOTU Car Rentals</p></Fade>
                    <Fade bottom><p className={`${styles.BannerConTextS}`}>made simple for you to rent a car!</p></Fade>
                  </div>
                  <div className={this.state.isBoxFixed ? `${styles.BannerSearchArea} ${styles.BannerSearchAreaFixed}`:`${styles.BannerSearchArea}`}>
                    <div className={`${styles.BannerSearchContainer}`}>

                      <div className={`${styles.BannerSearchLocation}`}>
                        <div className={`${styles.FixedFullSec}`}>
                          <label className={`${styles.BannerFormLabel} ${styles.LabelCus}`}>Pick Up Location</label>
                          <div className={`${styles.FormSec}`}>
                            <MapPin />
                            <Select className={(this.state.locationError)?`${styles.FormInput} ${styles.FormInputError}`:`${styles.FormInput}`} defaultValue={this.state.location} placeholder={"Select Airport"} options={[ { value: 'Faleolo International Airport', label: 'Faleolo International Airport' }]} onChange={this.changeAirport.bind(this)} styles={style} />
                          </div>
                        </div>
                        <div className={`${styles.FixedFullSec}`}>
                          <label className={`${styles.BannerFormLabel} ${styles.LabelCus}`}>Drop Off Location</label>
                          <div className={`${styles.FormSec}`}>
                            <MapPin />
                            <Select className={(this.state.dropOffLocationError)?`${styles.FormInput} ${styles.FormInputError}`:`${styles.FormInput}`} defaultValue={this.state.dropOffLocation} placeholder={"Select Airport"} options={[ { value: 'Faleolo International Airport', label: 'Faleolo International Airport' }]} onChange={this.changeDropOffLocation.bind(this)} />
                          </div>
                        </div>
                      </div>
                      
                      <div className={`${styles.BannerSearchDate}`}>
                        <div className={`${styles.FixedFullSec}`}>
                          <label className={`${styles.BannerFormLabel}`}>Pick-Up Date</label>
                          <div className={`${styles.FormSec}`}>
                            <Calendar />
                            <DatePicker selected={this.state.startDate} minDate={moment().toDate()} maxDate={moment().add(1,'y').toDate()} onChange={(date) => this.setStartDate(this,date,'startDate')} className={`${styles.FormInput}`} dateFormat="eee, d MMM y" />
                          </div>
                        </div>
                        <div className={`${styles.FixedFullSec}`}>
                          <label className={`${styles.BannerFormLabel}`}>Drop-Off Date</label>
                          <div className={`${styles.FormSec}`}>
                            <Calendar />
                            <DatePicker selected={this.state.endDate} minDate={this.state.startDate} maxDate={moment().add(1,'y').toDate()} onChange={(date) => this.setStartDate(this,date,'endDate')} className={`${styles.FormInput}`} dateFormat="eee, d MMM y" />
                          </div>
                        </div>                        
                      </div>
                      
                      <div className={`${styles.BannerSearchTime}`}>
                        <div className={`${styles.FixedFullSec}`}>
                          <label className={`${styles.BannerFormLabel}`}>Pick-Up Time</label>
                          <div className={`${styles.FormSec}`}>
                            <Clock />
                            <DatePicker selected={this.state.startTime} onChange={(date) => this.setStartDate(this,date,'startTime')} className={`${styles.FormInput}`} dateFormat="p" showTimeSelect timeFormat="p" timeIntervals={30} showTimeSelectOnly />
                          </div>
                        </div>
                        <div className={`${styles.FixedFullSec}`}>
                          <label className={`${styles.BannerFormLabel}`}>Drop-Off Time</label>
                          <div className={`${styles.FormSec}`}>
                            <Clock />
                            <DatePicker selected={this.state.endTime} onChange={(date) => this.setStartDate(this,date,'endTime')} className={`${styles.FormInput}`} dateFormat="p" showTimeSelect timeFormat="p" timeIntervals={30} showTimeSelectOnly />
                          </div>
                        </div>
                      </div>
                      <div className={`${styles.BannerSearchButton}`}>
                        <button type='buttom' className={`${styles.SearchBU}`} onClick={this.addBooking.bind(this)}><span>Continue Reservation</span></button>
                        {(this.state.isDiscount===1) && <p className={`${styles.CouponApplyText}`}><Check/> 10% off applied!</p>}
                      </div>
                    </div>
                  </div>
                </div>                
              </div>
            </div>
          </Parallax>
        </div>

        <div className={`${styles.BodyMessagesArea}`}>
          <img src="/images/CarBG.png" alt="" className={`${styles.CarBG}`} />
          <Fade right delay={800}><img src="/images/BodyCar.png" alt="" className={`${styles.BodyCar}`} /></Fade>
          <div className='container'>
            <div className={`${styles.BodyMRow}`}>
              <Fade bottom>

                <div className={`${styles.BodyFull}`}>
                  <h3 class={`${styles.WhyChooseTitleM}`}>Why Choose Us</h3>      
                  <div className={`${styles.WhyChooseRow}`}>

                    <div className={`${styles.WhyChooseDiv}`}>
                      <div className={`${styles.WhyChooseUnder}`}>
                        <img src="/images/why_choose_us_img1.svg" alt='' />
                        <h4 className={`${styles.WhyChooseTitle}`}>Pay online</h4>
                        <p className={`${styles.WhyChooseText}`}>Book a car, pay online, and receive a confirmation in real-time.</p>
                        <img src="/images/car_steering.svg" className={`${styles.WhyBGGra}`} alt="" />
                      </div>
                    </div>

                    <div className={`${styles.WhyChooseDiv}`}>
                      <div className={`${styles.WhyChooseUnder}`}>
                        <img src="/images/why_choose_us_img2.svg" alt='' />
                        <h4 className={`${styles.WhyChooseTitle}`}>Insured</h4>
                        <p className={`${styles.WhyChooseText}`}>All of the vehicles in our fleet have premium insurance.</p>
                        <img src="/images/car_steering.svg" className={`${styles.WhyBGGra}`} alt="" />
                      </div>
                    </div>

                    <div className={`${styles.WhyChooseDiv}`}>
                      <div className={`${styles.WhyChooseUnder}`}>
                        <img src="/images/why_choose_us_img3.svg" alt='' />
                        <h4 className={`${styles.WhyChooseTitle}`}>Add-Ons</h4>
                        <p className={`${styles.WhyChooseText}`}>Convenience at the click of a button. Choose from our extensive inventory to enhance your trip experience.</p>
                        <img src="/images/car_steering.svg" className={`${styles.WhyBGGra}`} alt="" />
                      </div>
                    </div>

                    <div className={`${styles.WhyChooseDiv}`}>
                      <div className={`${styles.WhyChooseUnder}`}>
                        <img src="/images/why_choose_us_img3.svg" alt='' />
                        <h4 className={`${styles.WhyChooseTitle}`}>Airport Delivery/ Return</h4>
                        <p className={`${styles.WhyChooseText}`}>Convenience delivery and return at the Faleolo International Airport.</p>
                        <img src="/images/car_steering.svg" className={`${styles.WhyBGGra}`} alt="" />
                      </div>
                    </div>

                  </div>
                </div>

              </Fade>

            </div>
          </div>
        </div>

        <Fade bottom>
        <div className='TrendingSec'>
          
            <Parallax bgImage="/images/TrendingBanner.jpg" strength={400}>
              <div style={{ height: '100%' }} className={`${styles.BannerConArea} ${styles.Black}`}>
                <img src="/images/TrendingWaveUp.svg" alt="" className={`${styles.TrendingWaveTop}`} />
                <div className='container'>
                  <div className={`${styles.TrendingContent}`}>
                    <h3 className={`${styles.TrendingTitle}`}>Feel the best experience with our rentals deals</h3>
                    <div className={`${styles.TrendingCarRow}`}>

                  <div className={`${styles.ProductSlider} ProductSlider`}>
                    <Carousel
                    swipeable={true}
                    arrows={true}
                    draggable={true}
                    showDots={false}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    // autoPlay={props.deviceType !== "mobile" ? true : false}
                    autoPlay={true}
                    autoPlaySpeed={5000}
                    keyBoardControl={true}
                    customTransition="2000ms all"
                    transitionDuration={100}
                    containerClass="carousel-container"
                    // removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                    deviceType={this.props.deviceType}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px">
                      {this.state?.carList?.map((item, index) => {
                      return(<Fade delay={400} key={index }>
                        <div className={`${styles.TrendingCarSec}`}>
                          <img src={item.image_url} alt="" />
                          <div className={`${styles.TrendingCarConSec}`}>
                            <p className={`${styles.TrendingCarConTitle}`}>{item.title}</p>
                            <p className={`${styles.TrendingCarConTitleSM}`}>{item.type}</p>
                            <p className={`${styles.TrendingCarConTitleSM}`}><span>{item.transmission}</span></p>
                            <div className={`${styles.TrendingBUSec}`}>
                              <button className={`${styles.TrendingBUBrown}`}><span>{this.state?.currency} {!this.state.currencyLoading && ((item.price * this.state.currencyRate).toFixed(2))+'/Day'}</span></button>
                              <button className={`${styles.TrendingBUGreen}`} onClick={this.goToBookNow.bind(this)}><span>Book Now</span></button>
                            </div>
                          </div>
                        </div>
                      </Fade> )
                     })}
                    </Carousel>
                  </div>
                      
                    {/* {this.state?.carList?.map((item, index) => {
                      return(<Fade delay={400} key={index }>
                        <div className={`${styles.TrendingCarSec}`}>
                          <img src={item.image_url} alt="" />
                          <div className={`${styles.TrendingCarConSec}`}>
                            <p className={`${styles.TrendingCarConTitle}`}>{item.title}</p>
                            <p className={`${styles.TrendingCarConTitleSM}`}>{item.type}</p>
                            <p className={`${styles.TrendingCarConTitleSM}`}><span>{item.transmission}</span></p>
                            <div className={`${styles.TrendingBUSec}`}>
                              <button className={`${styles.TrendingBUBrown}`}><span>{this.state?.currency} {!this.state.currencyLoading && ((item.price * this.state.currencyRate).toFixed(2))+'/Day'}</span></button>
                              <button className={`${styles.TrendingBUGreen}`} onClick={this.goToBookNow.bind(this)}><span>Book Now</span></button>
                            </div>
                          </div>
                        </div>
                      </Fade> )
                     })} */}
                     </div>
                  </div>                
                </div>
                <img src="/images/TrendingWaveDown.svg" alt="" className={`${styles.TrendingWaveDown}`} />
              </div>
            </Parallax>
          
        </div>
        </Fade>

        <div className={`${styles.CustomerReview} CustomerReview`}>
          <Fade bottom>
            <div className="container">
              <h3 className={`${styles.CustomerReviewTitle}`}>Hear it from Our Customers</h3>
              <div className={`${styles.ReviewSlider}`}>
                <Carousel 
                swipeable={true}
                arrows={true}
                draggable={true}
                showDots={false}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={5000000}
                keyBoardControl={true}
                customTransition="2000ms all"
                transitionDuration={400}
                containerClass="carousel-container"
                // removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                //deviceType={props.deviceType}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                >

                  {this.state.testimonials.map((item)=>{
                    return <div className={`${styles.ReviewSlide}`} key={item?.id}>
                      <div className={`${styles.ReviewSlideInner}`}>
                        {/*<div className={`${styles.ReviewSlideInnerImgDiv}`}>
                          <img src={item?.car_with_image?.image_url} alt="" />
                        </div>*/}
                        <p className={`${styles.ReviewSlideInnerTitle}`}>{item?.car_with_image?.title}</p>
                        <div className={`${styles.ReviewProfile}`}>
                          <div className={`${styles.ReviewProfileSec}`}>
                            <img src="/images/user_avatar_new.png" alt="" />
                            <div className={`${styles.ReviewProfileDetails}`}>
                              <p className={`${styles.Title}`}>{item?.name}</p>
                            </div>
                          </div>
                          <p className={`${styles.ReviewRatingSec}`}>
                            <Rating value={item?.rating} readOnly emptyIcon={<Star style={{ opacity: 0.4 }} />} />
                            <span className={`${styles.Rating}`}>{item?.rating}/5</span>
                          </p>
                        </div>
                        <p className={`${styles.ReviewSlideInnerTitleSM}`}>{item?.comments}</p>
                      </div>
                    </div>
                  })}

                </Carousel>
              </div>
            </div>
          </Fade>
        </div>

        {/* <div className={`${styles.Mapbox}`}>
          <Fade bottom>
            <h3 className={`${styles.MapBoxTitle}`}>Our Location</h3>
            <Iframe source={'https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d33796.64732039928!2d-172.0176135010255!3d-13.841572878534288!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sSecond%20Floor%2C%20Booth%20N%C5%8D.55%2C%20Faleolo%20International%20Airport%20%2C%20Samoa!5e0!3m2!1sen!2sin!4v1675973381523!5m2!1sen!2sin'} />
          </Fade>
        </div> */}

      </React.Fragment>
  )}
}

export default withNavigateHook(Home);