import React, { useState } from 'react';
import styles from './header.module.css';
import {  Link as RouterLink } from "react-router-dom";
import {Menu as MenuIcon, User, LogOut, ChevronDown, Bookmark} from 'react-feather';
import {Menu, MenuItem} from '@mui/material';
import {  useNavigate } from "react-router-dom";
import {authData} from "../../admin/components/getAuth";



function Header () {
  const auth = authData();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const navigate = useNavigate();

  const logout=()=>{
    localStorage.removeItem('authData');
    navigate('/');
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const goToReservation = ()=>{
    navigate('/reservation');
  }

    return (

        <header className={`${styles.HeaderMain}`}>
          <div className='container'>
            <div className={`${styles.HeaderRow}`}>
              <div className={`${styles.HeadLogo}`}><img src="/images/moto-logo.svg" alt="" /></div>
              <div className={`${styles.HeadMenu} ${showMobileMenu ? styles.Open : ''}`}>
                <ul>
                  <li><RouterLink to={'/'}>Home</RouterLink></li>
                  <li><RouterLink to={'/reservation'}>Reservation</RouterLink></li>
                  <li><RouterLink to={'/car-services'}>Cars & Services</RouterLink></li>
                  <li><a href={'https://blog.motucarrentals.com/'} target="_blank" rel="noreferrer">Blog</a></li>
                  <li><RouterLink onClick={handleClick}><User /> <span className="UserName">{auth?.name}</span> <ChevronDown className={`${styles.Chev}`}/></RouterLink></li>
                </ul>
                <Menu className={`${styles.UserDropMenu}`}
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={handleClose}><RouterLink to={'/customer/my-bookings'} className={`${styles.UserProfile}`}><Bookmark /> My Bookings</RouterLink></MenuItem>
                  <MenuItem onClick={handleClose}><RouterLink to={'/customer/my-profile'} className={`${styles.UserProfile}`}><User /> My Profile</RouterLink></MenuItem>
                  <MenuItem onClick={handleClose}><button onClick={logout.bind(this)} className={`${styles.UserLogoutBU}`}><LogOut/>Logout</button></MenuItem>
                </Menu>
                <button className={`${styles.HeaderBU}`} onClick={goToReservation}><span>Book Now</span></button>
              </div>
              <button onClick={() => setShowMobileMenu(v => !v)} className={`${styles.MenuHamBU}`}><MenuIcon/></button>
            </div>
          </div>
        </header>
  )}

export default (Header);